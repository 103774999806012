export default {
  props: {
    value: {},
    type: String
  },
  functional: true,
  render (h, context) {
    return h('span', context.props.value.map(value => value.name || value).join(', '))
  }
}
