<template>
  <div class="production-manager-overview">
    <!--<FavoriteFeedOverview />-->
    <FlockOverview />
    <PoultryHousesOverview />
  </div>
</template>

<script>

// import FavoriteFeedOverview from './FavoriteFeedOverview'
import FlockOverview from './FlockOverview'
import PoultryHousesOverview from './PoultryHousesOverview'

export default {
  components: {
    // FavoriteFeedOverview,
    FlockOverview,
    PoultryHousesOverview
  }
}

</script>

<style lang="scss">

.production-manager-overview {
  height: 100%;
  max-height: 100%;
  display: grid;
  grid: 1fr 1fr 1fr / auto;
}

</style>
