var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"feed-recommendation"},[_c('p',[_vm._v(_vm._s(_vm.title))]),_c('SimpleTable',{attrs:{"data":_vm.internalFeeds,"columns":_vm.columns,"localize-headers":""},scopedSlots:_vm._u([{key:"timeRange",fn:function(ref){
var row = ref.row;
var value = ref.value;
var rowIndex = ref.rowIndex;
return [(row.isEditMode)?_c('div',[_c('input',{staticClass:"time-range-input",attrs:{"type":"number"},domProps:{"value":row.start},on:{"input":function($event){return _vm.update(rowIndex, 'start', $event.target.valueAsNumber)}}}),_c('span',[_vm._v(" - ")]),_c('input',{staticClass:"time-range-input",attrs:{"type":"number"},domProps:{"value":row.end},on:{"input":function($event){return _vm.update(rowIndex, 'end', $event.target.valueAsNumber)}}})]):_c('span',[_vm._v(" "+_vm._s(row.start)+" - "+_vm._s(row.end)+" ")])]}},{key:"feed",fn:function(ref){
var row = ref.row;
var value = ref.value;
var rowIndex = ref.rowIndex;
return [(row.isEditMode)?_c('VueSelectize',{attrs:{"value":row.productNumber,"options":_vm.allFeeds,"fields":{text: 'name', value: 'productNumber'}},on:{"input":function($event){return _vm.update(rowIndex, 'feed', $event)}}}):[(row.name)?_c('span',[_vm._v(_vm._s(row.name))]):_c('IxRes',[_vm._v("NoEntry")])]]}},{key:"buttons",fn:function(ref){
var row = ref.row;
return [(row.isEditMode)?_c('IxButton',{attrs:{"icon":"check"},on:{"click":function($event){return _vm.endEdit(row)}}}):_c('IxButton',{attrs:{"icon":"edit"},on:{"click":function($event){return _vm.beginEdit(row)}}}),_c('IxButton',{attrs:{"icon":"trash"},on:{"click":function($event){return _vm.remove(row)}}})]}}])}),_c('a',{on:{"click":_vm.addFeed}},[_c('IxRes',[_vm._v("AddFeed")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }