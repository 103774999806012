<template>
  <div v-if="includeTree" class="page-with-tree">
    <div class="tree-container">
      <RocketNav
        v-model="selectedEntity"
        :current-org="orgUnit"
        :parent-org="parent"
        :items="entities"
        class="rocket-nav"
      >
        <template #farm="{item, selected}">
          <FarmCard :farm="item" />
        </template>
      </RocketNav>
    </div>
    <div v-if="displayMainContent" class="main-content">
      <transition name="component-fade" mode="out-in">
        <component :is="currentPage" />
      </transition>
    </div>
  </div>
  <PageWithoutTree v-else class="page-without-tree" />
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

import RocketNav from '@components/nav/RocketNav'

import Overview from './Overview'
import PageWithoutTree from './PageWithoutTree'
import FarmCard from '../components/FarmCard'

export default {
  translationNamespace: 'Areas.PoultryManagement.SR_PoultryManagement',

  components: {
    Error,
    Overview,
    RocketNav,
    FarmCard,
    PageWithoutTree
  },
  data () {
    return {
      selectedEntity: null,
      displayMainContent: false
    }
  },
  computed: {
    ...mapGetters('poultryManagement/navigation', [
      'tree',
      'entityLookup',
      'parentLookup'
    ]),
    ...mapGetters('poultryManagement/farmer', [
      'currentPage',
      'includeTree'
    ]),
    orgUnit () {
      if (this.selectedEntity) {
        return this.entityLookup[this.selectedEntity]
      } else return null
    },
    entities () {
      if (this.selectedEntity && this.entityLookup[this.selectedEntity]) {
        return this.entityLookup[this.selectedEntity].children || []
      } else return this.tree
    },
    parent () {
      if (this.selectedEntity) {
        return this.entityLookup[this.parentLookup[this.selectedEntity]]
      } else return {}
    }
  },
  methods: {
    ...mapActions('poultryManagement', [
      'checkRoleAndGotoFarm'
    ])
  },
  watch: {
    orgUnit (orgUnit) {
      if (orgUnit && orgUnit.type === 'farm') {
        this.displayMainContent = true
        this.checkRoleAndGotoFarm(orgUnit)
      } else {
        this.displayMainContent = false
      }
    }
  },
  mounted () {
    var params = new URLSearchParams(window.location.search)

    if (params.has('entity')) {
      this.selectedEntity = params.get('entity')
    }
  }
}
</script>

<style lang="scss" scoped>

.page-with-tree {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: 3fr 7fr;
  &>div {
    padding: {
      top: 10px;
      right: 10px;
      left: 10px;
      bottom: 0px;
    }
  }

  .rocket-nav {
      width: 100%;
      overflow: hidden;
  }

  .tree-container {
    border-right: 1px solid dimgray;
    margin: 0 0 0 auto;
    height: 100%;
    width: 100%;
    display: grid;
    grid: auto min-content / 1fr;
  }

  .main-content {
    .component-fade-enter-active, .component-fade-leave-active {
      transition: opacity .3s ease;
    }

    .component-fade-enter, .component-fade-leave-to{
      opacity: 0;
    }
  }
}

.page-without-tree {
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow: auto;
}
</style>
