<template>
  <div class="farm-statistics-panel">
    <div>{{ numFilledOutText }}</div>
    <div>
      <span>
        <IxRes>NumberOfAnimalsTotal</IxRes>{{ ': ♀ ' + currentDay.numberHensTotal.toLocaleString() + ' ♂ ' + currentDay.numberRoostersTotal.toLocaleString() }}
      </span>
      <span class="overview-section">
        <IxRes>Losses</IxRes> {{ ':  ♀' + currentDay.henLosses.toLocaleString() }}
        <FontAwesomeIcon
          class="icon-warning" :icon="henLossesStyle.icon"
          :transform="henLossesStyle.transform" :style="henLossesStyle.style"
        />
        {{ '♂ ' + currentDay.roosterLosses.toLocaleString() }}
        <FontAwesomeIcon
          class="icon-warning" :icon="roosterLossesStyle.icon"
          :transform="roosterLossesStyle.transform" :style="roosterLossesStyle.style"
        />
      </span>
    </div>
    <div>
      <span>
        <IxRes>NumberOfEggsTotal</IxRes>{{ ': ' + currentDay.eggsTotal.toLocaleString() }}
        <FontAwesomeIcon
          class="icon-warning" :icon="eggsTotalStyle.icon"
          :transform="eggsTotalStyle.transform" :style="eggsTotalStyle.style"
        />
      </span>
      <span class="overview-section">
        <IxRes>BroodEggs</IxRes>{{ ': ' + currentDay.broodEggs.toLocaleString() }}
        <FontAwesomeIcon
          class="icon-warning" :icon="broodEggsStyle.icon"
          :transform="broodEggsStyle.transform" :style="broodEggsStyle.style"
        />
      </span>
      <span class="overview-section">
        <IxRes>UnbreadableEggs</IxRes>{{ ': ' + currentDay.unbreadableEggs.toLocaleString() }}
        <FontAwesomeIcon
          class="icon-warning" :icon="unbreadableEggsStyle.icon"
          :transform="unbreadableEggsStyle.transform" :style="unbreadableEggsStyle.style"
        />
      </span>
      <span class="overview-section">
        <IxRes>FloorEggs</IxRes> {{ ': ' + currentDay.floorEggs.toLocaleString() }}
        <FontAwesomeIcon
          class="icon-warning" :icon="floorEggsStyle.icon"
          :transform="floorEggsStyle.transform" :style="floorEggsStyle.style"
        />
      </span>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {mapResources} from '@helpers/vuex'

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faArrowRight, faCircle} from '@fortawesome/free-solid-svg-icons'

import IxRes from '@components/IxRes'

const styles = {
  decreaseGood: {icon: faArrowRight, transform: {rotate: 45}, style: {color: 'green'}},
  decreaseBad: {icon: faArrowRight, transform: {rotate: 45}, style: {color: 'red'}},
  increaseGood: {icon: faArrowRight, transform: {rotate: -45}, style: {color: 'green'}},
  increaseBad: {icon: faArrowRight, transform: {rotate: -45}, style: {color: 'red'}},
  stable: {icon: faCircle, transform: 'shrink-6', style: {color: 'grey'}}
}

export default {
  translationNamespace: 'Areas.PoultryManagement.SR_PoultryManagement',

  components: {
    IxRes,
    FontAwesomeIcon
  },
  computed: {
    ...mapGetters('poultryManagement/farmer', [
      'poultryHouses'
    ]),
    ...mapGetters('poultryManagement/farmer/farmStatistics', [
      'currentDay',
      'previousDay'
    ]),
    ...mapResources([
      'Areas.PoultryManagement.SR_PoultryManagement'
    ]),
    numFilledOutText () {
      let numFilledOut = this.poultryHouses.filter(x => x.status === 'completed').length
      return this.$i18n.translate('Areas.PoultryManagement.SR_PoultryManagement.FilledOutPoultryHousesStatus', {numFilledOut: numFilledOut, numPoultryHouses: this.poultryHouses.length})
    },
    henLossesStyle () {
      let henLosses = this.currentDay.henLosses
      return henLosses > this.previousDay.henLosses ? styles.increaseBad : henLosses < this.previousDay.henLosses ? styles.decreaseGood : styles.stable
    },
    roosterLossesStyle () {
      let roosterLosses = this.currentDay.roosterLosses
      return roosterLosses > this.previousDay.roosterLosses ? styles.increaseBad : roosterLosses < this.previousDay.roosterLosses ? styles.decreaseGood : styles.stable
    },
    eggsTotalStyle () {
      let eggs = this.currentDay.eggsTotal
      return eggs > this.previousDay.eggsTotal ? styles.increaseGood : eggs < this.previousDay.eggsTotal ? styles.decreaseBad : styles.stable
    },
    broodEggsStyle () {
      let broodEggs = this.currentDay.broodEggs
      return broodEggs > this.previousDay.broodEggs ? styles.increaseGood : broodEggs < this.previousDay.broodEggs ? styles.decreaseBad : styles.stable
    },
    unbreadableEggsStyle () {
      let unbreadableEggs = this.currentDay.unbreadableEggs
      return unbreadableEggs > this.previousDay.unbreadableEggs ? styles.increaseBad : unbreadableEggs < this.previousDay.unbreadableEggs ? styles.decreaseGood : styles.stable
    },
    floorEggsStyle () {
      let floorEggs = this.currentDay.floorEggs
      return floorEggs > this.previousDay.floorEggs ? styles.increaseBad : floorEggs < this.previousDay.floorEggs ? styles.decreaseGood : styles.stable
    }
  }
}

</script>

<style lang="scss" scoped>
.farm-data-panel {
  border-top: 1px solid grey;
}
.overview-section {
  margin-left: 20px;
}
</style>
