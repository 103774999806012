<template lang="html">
  <div class="input-with-label">
    <span>{{ description }} {{ includeColon ? ': ' : '&nbsp;' }}</span>
    <span>
      <input :value="internalValue" type="number" @input="onInput($event.target.value)"> {{ addon }}
    </span>
  </div>
</template>

<script>

export default {
  components: {},
  props: {
    value: Number || String, // TODO: just a workaround, this component needs much more functionality to prevent errors and support multiple formats
    description: String,
    addon: String,
    includeColon: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    valueIsString () {
      return typeof this.value === 'string'
    },
    internalValue () {
      return this.value ? this.valueIsString ? parseInt(this.value) : this.value : 0
    }
  },
  methods: {
    onInput (value) {
      this.$emit('input', parseInt(value))
    }
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
.input-with-label {
  display: flex;
  flex-direction: column;
}
</style>
