<template lang="html" functional>
  <i class="fa" :class="[props.value === null ? 'fa-question' : props.value ? 'fa-check' : 'fa-close']" />
</template>

<script>
export default {
  props: {
    value: Boolean
  }
}
</script>
