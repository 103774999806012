<template>
  <div class="error-container">
    <h3>
      <IxRes>
        ErrorHappend
      </IxRes>
    </h3>
    <div>
      {{ errorMessage }}
    </div>
    <div>
      <IxButton back large @click="goToPage('Overview')" />
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'

import IxButton from '@components/IxButton'

export default {
  components: {
    IxButton
  },

  computed: {
    ...mapGetters('poultryManagement/productionManager', [
      'errorMessage'
    ])
  },

  methods: {
    ...mapMutations('poultryManagement/productionManager', [
      'goToPage'
    ])
  }
}

</script>
