<template>
  <form class="instablement-container">
    <h3>
      <IxRes>instableFlock</IxRes>
    </h3>

    <div>
      <TextInput
        v-model="number"
        name="flock-name"
        required
      >
        <IxRes slot="label">number</IxRes>
      </TextInput>
      <VueSelectize
        v-model="breed"
        :options="breeds"
        :label="SR_PoultryManagement.race"
        :fields="{text: 'breedName'}"
        class="breed-select"
        name="breed-selection"
      />
    </div>

    <div class="type-box">
      <label>
        <IxRes>type</IxRes>
      </label>
      <BsRadio v-model="type" value="eggproduction">
        <IxRes>eggProduction</IxRes>
      </BsRadio>
      <BsRadio v-model="type" value="breeding">
        <IxRes>breeding</IxRes>
      </BsRadio>
    </div>

    <div>
      <DatePicker v-model="hatchingDate" name="hatching-date">
        <IxRes slot="label">hatchingDate</IxRes>
      </DatePicker>
      <DatePicker v-model="placementDate" name="placement-date">
        <IxRes slot="label">instablementDate</IxRes>
      </DatePicker>
      <NumericInput v-model="daysOfLife" name="days-of-life">
        <IxRes slot="label">daysOfLife</IxRes>
      </NumericInput>
      <div>
        <label>
          <IxRes>estimatedFinalDate</IxRes>
        </label>
        <p>{{ estimatedFinalDate }}</p>
      </div>
    </div>

    <div class="type-box">
      <SimpleTable
        :data="stables" :columns="columns"
        localize-headers :highlight="highlight"
      >
        <template slot="stable" slot-scope="{row, value, rowIndex}">
          <VueSelectize
            v-if="row.isEditMode" allow-empty
            :value="value" :options="poultryHouses"
            :fields="{text: 'poultryHouseNumber', value: 'id'}"
            name="poultry-houses"
            @input="update(rowIndex, 'stable', $event)"
          />
          <span v-else>{{ value.poultryHouseNumber }}</span>
        </template>
        <template slot="numHens" slot-scope="{row, value, rowIndex}">
          <NumericInput
            v-if="row.isEditMode"
            :value="value"
            name="num-hens"
            @input="update(rowIndex, 'numHens', $event)"
          />
          <span v-else>{{ value }}</span>
        </template>
        <template slot="numRoosters" slot-scope="{row, value, rowIndex}">
          <NumericInput
            v-if="row.isEditMode"
            :value="value"
            name="num-roosters"
            @input="update(rowIndex, 'numRoosters', $event)"
          />
          <span v-else>{{ value }}</span>
        </template>
        <template slot="buttons" slot-scope="{row}">
          <template v-if="!row.isFooter">
            <IxButton
              v-if="row.isEditMode"
              save
              icon="check"
              @click="endEdit(row)"
            >
              &nbsp;
            </IxButton>
            <IxButton v-else edit @click="beginEdit(row)">
              &nbsp;
            </IxButton>
            <IxButton remove @click="remove(row)">
              &nbsp;
            </IxButton>
          </template>
        </template>
      </SimpleTable>
    </div>
    <span>
      <a @click="addStable">
        <IxRes>addStable</IxRes>
      </a>
    </span>
    <HelpBox v-if="fieldStates['added-stables'] === 'error'" type="danger">
      <IxRes>invalidStables</IxRes>
    </HelpBox>
    <div>
      <p>
        <IxRes>someLongTextToExplainWhatsGoingOn</IxRes>
      </p>
    </div>

    <div>
      <IxButton
        save
        icon="blank"
        large
        @click="submit"
      >
        <IxRes>instableFlock</IxRes>
      </IxButton>
      <IxButton cancel large @click="cancel" />
    </div>
  </form>
</template>

<script>
import {mapActions, mapMutations, mapGetters} from 'vuex'
import FormPartMixin from '@components/forms/FormPartMixin'
import {mapResources} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import moment from 'moment'

import TextInput from '@components/forms/TextInput'
import NumericInput from '@components/forms/NumericInput'
import IxButton from '@components/IxButton'
import SimpleTable from '@components/table/SimpleTable'
import BsRadio from '@components/bootstrap/BsRadio'
import DatePicker from '@components/forms/VueDateTimePicker'
import VueSelectize from '@components/VueSelectize'
import HelpBox from '@components/help/HelpBox'

export default {
  translationNamespace: 'Areas.PoultryManagement.SR_PoultryManagement',

  components: {
    IxButton,
    SimpleTable,
    TextInput,
    NumericInput,
    BsRadio,
    DatePicker,
    VueSelectize,
    HelpBox
  },
  mixins: [
    FormPartMixin
  ],
  data () {
    return {
      number: '',
      breed: null,
      type: 'eggproduction',
      hatchingDate: moment(),
      placementDate: moment(),
      daysOfLife: 361,
      assignedStables: [],
      highlight: x => x.isFooter ? ['success'] : []
    }
  },

  computed: {
    ...mapResources([
      '@pm.SR_PoultryManagement',
      'Messages.State.SR_StateMessages'
    ]),
    ...mapGetters('poultryManagement', [
      'breeds'
    ]),
    ...mapGetters('poultryManagement/productionManager', [
      'currentFarm',
      'poultryHouses'
    ]),
    estimatedFinalDate () {
      return this.hatchingDate.clone().add(this.daysOfLife, 'day').format('L')
    },
    columns () {
      return {
        stable: 'Areas.PoultryManagement.SR_PoultryManagement.PoultryHouse',
        numHens: 'Areas.PoultryManagement.SR_PoultryManagement.numberFemale',
        numRoosters: 'Areas.PoultryManagement.SR_PoultryManagement.numberMale',
        buttons: {title: ''}
      }
    },
    stables () {
      return this.assignedStables.concat({
        stable: {poultryHouseNumber: 'Gesamt', value: null},
        numHens: this.assignedStables.reduce((acc, row) => acc + row.numHens, 0),
        numRoosters: this.assignedStables.reduce((acc, row) => acc + row.numRoosters, 0),
        isFooter: true
      })
    }
  },

  methods: {
    ...mapActions('poultryManagement/productionManager', [
      'instableFlock'
    ]),
    ...mapMutations('poultryManagement/productionManager', [
      'goToPage'
    ]),
    validateStable () {
      const residents = (stable) => {
        return stable.numHens > 0 && stable.numRoosters > 0
      }
      const hasResidents = this.assignedStables.every(residents)
      const hasStables = this.assignedStables.length > 0
      this.$set(this.fieldStates, 'added-stables', hasStables && hasResidents ? 'success' : 'error')
    },
    async submit () {
      this.validate()
      this.validateStable()

      await this.$nextTick()

      if (this.state !== 'success') return

      const flock = {
        flockNumber: this.number,
        farmId: this.currentFarm.id,
        breed: this.breed.breedName,
        flockType: this.type,
        hatchingDate: this.hatchingDate.format('YYYY-MM-DD'),
        placementDate: this.placementDate.format('YYYY-MM-DD'),
        daysOfLife: this.daysOfLife,
        assignedPoultryHouses: this.assignedStables.map(item => ({poultryHouseNumber: item.stable.poultryHouseNumber, numHens: item.numHens, numRoosters: item.numRoosters}))
      }

      try {
        await this.instableFlock(flock)
        notifications.success(this.SR_StateMessages.SaveSuccess)
      } catch (error) {
        notifications.error(this.SR_StateMessages.SaveError)
        console.error(error)
      }
    },
    cancel () {
      this.goToPage('Overview')
    },
    addStable () {
      this.assignedStables.push({
        stable: null,
        numHens: 0,
        numRoosters: 0,
        isEditMode: true
      })
    },
    beginEdit (row) {
      row.isEditMode = true
    },
    endEdit (row) {
      row.isEditMode = false
    },
    remove (value) {
      const idx = this.assignedStables.findIndex(ele => ele.stable.poultryHouseNumber === value.stable.poultryHouseNumber)
      if (idx < 0) return
      this.assignedStables.splice(idx, 1)
    },
    update (index, property, value) {
      const row = this.assignedStables[index]
      this.$set(row, property, value)
      this.$set(this.assignedStables, index, row)
    }
  },
  watch: {
    stables () {
      this.$set(this.fieldStates, 'added-stables', 'success')
    }
  },
  created () {
    // set default breed (breeds loaded in ProductionManager)
    if (this.breeds && this.breeds.length) {
      this.breed = this.breeds[0]
    }
  }
}

</script>

<style lang="scss">
.instablement-container {

  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
    flex-direction: row;
    margin-top: 2em;

    & > *:not(:first-child) {
      margin-left: 10px;
    }
  }

  &:first-child {
    margin-top: 0px;
  }

  .type-box {
    display: flex;
    flex-direction: column;
  }

  .breed-select {
    min-width: 200px;
  }
}
</style>
