<template>
  <div class="overview">
    <div class="day-selector-row">
      <span class="info">
        <i v-if="dateIsPrevious" class="fa fa-exclamation-triangle" style="color:red" />
        {{ infoText }}
      </span>
      <span class="previous" @click="selectPreviousDay">
        <i class="fa fa-chevron-left" />
        <IxRes>PreviousDay</IxRes>
      </span>
      <span class="date-selector">
        <input v-model="selectedDate" type="date" required>
      </span>
      <span v-if="dateIsPrevious" class="next" @click="selectNextDay">
        <IxRes>NextDay</IxRes>
        <i class="fa fa-chevron-right" />
      </span>
      <span v-if="!dateIsToday" class="back-to-current" @click="selectCurrentDay">
        <i class="fa fa-reply" />
        <a><IxRes>BackToToday</IxRes></a>
      </span>
    </div>
    <div class="farm-management-row">
      <BsTabs v-model="currentComponent" :tabs="tabs" class="navigation-tabs" />
      <keep-alive>
        <component :is="currentComponent" />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {mapMutations, mapActions} from 'vuex'
import {mapResources, mapFormFields} from '@helpers/vuex'

import IxRes from '@components/IxRes'
import BsTabs from '@components/bootstrap/BsTabs'

import EnergyData from './EnergyData'
import Materials from './Materials'
import PoultryHouses from './PoultryHouses'
import SilosAndShipments from './SilosAndShipments'

export default {
  translationNamespace: 'Areas.PoultryManagement.SR_PoultryManagement',

  components: {
    IxRes,
    BsTabs,
    EnergyData,
    Materials,
    PoultryHouses,
    SilosAndShipments
  },
  data () {
    return {
      currentComponent: 'PoultryHouses'
    }
  },
  computed: {
    ...mapFormFields('poultryManagement/farmer', {
      'data.selectedDate': {
        read: value => value,
        write: value => value,
        mutation: 'setSelectedDate'
      }
    }),
    ...mapResources([
      'Areas.PoultryManagement.SR_PoultryManagement'
    ]),
    tabs () {
      return [
        {title: this.SR_PoultryManagement.PoultryHouses, value: 'PoultryHouses'},
        {title: this.SR_PoultryManagement.SilosAndShipments, value: 'SilosAndShipments'},
        {title: this.SR_PoultryManagement.EnergyData, value: 'EnergyData'},
        {title: this.SR_PoultryManagement.Materials, value: 'Materials'}
      ]
    },
    dateIsToday () {
      return moment().format('YYYY-MM-DD') === moment(this.selectedDate).format('YYYY-MM-DD')
    },
    dateIsPrevious () {
      return moment(this.selectedDate).isBefore(moment().format('YYYY-MM-DD'))
    },
    infoText () {
      return this.dateIsToday ? this.SR_PoultryManagement.Today : this.SR_PoultryManagement.EditingPreviousDayWarning
    }
  },
  methods: {
    ...mapMutations('poultryManagement', [
      'goToView'
    ]),
    ...mapActions('poultryManagement/farmer', [
      'loadPoultryHouses'
    ]),
    ...mapActions('poultryManagement/farmer/farmStatistics', [
      'loadFarmStatistics'
    ]),
    selectPreviousDay () {
      this.selectedDate = moment(this.selectedDate).subtract(1, 'days').format('YYYY-MM-DD')
    },
    selectNextDay () {
      this.selectedDate = moment(this.selectedDate).add(1, 'days').format('YYYY-MM-DD')
    },
    selectCurrentDay () {
      this.selectedDate = moment().format('YYYY-MM-DD')
    }
  },
  watch: {
    selectedDate () {
      this.loadPoultryHouses()
      this.loadFarmStatistics()
    }
  }
}
</script>

<style lang="scss" scoped>
.overview {
  display: grid;
  grid-template-rows: 50px auto;
  width: 100%;
  height: 100%;
}
.day-selector-row {
  text-align: center;
  display: grid;
  grid-template-areas: ". info . . ."
                       "previous date-selector next back-to-current .";
  grid-template-columns: 200px 350px 200px 150px auto;
}

.info {
  grid-area: info;
}

.date-selector {
  grid-area: date-selector;
}

.previous {
  grid-area: previous;
  cursor: pointer;
}

.next {
  grid-area: next;
  cursor: pointer;
}

.back-to-current {
  grid-area: back-to-current;
}
</style>
