<template>
  <div class="main-content">
    <SimpleSwitch v-if="isSecondVisit" v-model="recordSecondVisitInFirst" class="record-in-first-visit">
      <template #offLabel>
        <IxRes>IsRecordedInFirstVisit</IxRes>
      </template>
    </SimpleSwitch>
    <div class="visit-input-container" :class="{inactive: recordSecondVisitInFirst}">
      <div class="hens">
        <PoultryHouseVisitSelectionPanel :gender-is-hen="true" :disabled="recordSecondVisitInFirst" />
      </div>
      <div class="roosters">
        <PoultryHouseVisitSelectionPanel :gender-is-hen="false" :disabled="recordSecondVisitInFirst" />
      </div>
      <div class="spacer" />
      <div class="eggs">
        <p class="large-text">
          <IxRes>Eggs</IxRes>
        </p>
        <p class="bold-text">
          <IxRes>FloorEggs</IxRes>:
        </p>
        <input v-model="currentVisit.floorEggs" type="number" :disabled="recordSecondVisitInFirst">
      </div>
      <div class="remarks">
        <TextInput
          v-model="currentVisit.remarks" multiline
          :rows="6" :disabled="recordSecondVisitInFirst"
          label="Notizen:" placeholder="optional"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {mapResources} from '@helpers/vuex'

import IxRes from '@components/IxRes'
import TextInput from '@components/forms/TextInput'
import SimpleSwitch from '@components/forms/SimpleSwitch'

import PoultryHouseVisitSelectionPanel from './PoultryHouseVisitSelectionPanel'

export default {
  translationNamespace: 'Areas.PoultryManagement.SR_PoultryManagement',

  components: {
    IxRes,
    PoultryHouseVisitSelectionPanel,
    SimpleSwitch,
    TextInput
  },
  data () {
    return {
      recordSecondVisitInFirst: false
    }
  },
  computed: {
    ...mapGetters('poultryManagement/farmer', [
      'currentVisit'
    ]),
    ...mapResources(['Areas.PoultryManagement.SR_PoultryManagement']),
    isSecondVisit () {
      return this.currentVisit.run === 2
    }
  },
  watch: {
    recordSecondVisitInFirst (value) {
      if (value) {
        this.currentVisit.floorEggs = 0
        this.currentVisit.remarks = this.SR_PoultryManagement.IsRecordedInFirstVisit
        this.currentVisit.recordedInFirstRun = true
      } else {
        this.currentVisit.remarks = ''
        this.currentVisit.recordedInFirstRun = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.main-content {
  width: 100%;
  height: 100%;
  display: grid;
}

.record-in-first-visit {
  justify-content: center;
}

.visit-input-container {
  display: grid;
  column-gap: 10px;
  row-gap: 10px;
  grid-template-columns: auto 1px auto;
  grid-template-areas: "hens spacer roosters"
                       "eggs . remarks";

  &.inactive {
    opacity: 0.6;
  }
}

.hens {
  grid-area: hens;
  border-right: 1px solid grey;
  padding-right: 20px;
}

.roosters {
  grid-area: roosters;
}

.spacer {
  grid-area: spacer;  // TODO: replace spacer solution to create border
}

.eggs {
  grid-area: eggs
}

.remarks {
  grid-area: remarks
}

.bold-text {
  font-weight: bold;
}
</style>
