<template>
  <div class="poultry-house-content">
    <div class="poultry-house-card-container">
      <div v-if="poultryHouses.length > 0">
        <PoultryHouseCard
          v-for="poultryHouse in poultryHouses"
          :key="poultryHouse.poultryHouseNumber"
          :poultry-house="poultryHouse"
        />
      </div>
      <div v-else>
        <IxRes>NoPoultryHousesWithFlocks</IxRes>
      </div>
    </div>
    <FarmStatisticsPanel />
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

import FarmStatisticsPanel from './FarmStatisticsPanel'
import PoultryHouseCard from './PoultryHouseCard'

import IxRes from '@components/IxRes'

export default {
  translationNamespace: 'Areas.PoultryManagement.SR_PoultryManagement',

  components: {
    IxRes,
    FarmStatisticsPanel,
    PoultryHouseCard
  },
  computed: {
    ...mapGetters('poultryManagement/farmer', [
      'poultryHouses'
    ])
  }
}
</script>

<style lang="scss" scoped>
.poultry-house-content {
  width: 100%;
  height: 90%;
  /* TODO: adjust parent containers in order to avoid setting height here */
  display: grid;
  grid-template-rows: auto 80px;
}

.farm-data-row {
  text-align: center;
  background-color: bisque;
}
</style>
