<template lang="html">
  <span>
    <div v-if="!inline" class="radio bs-radio radio-button">
      <label>
        <input
          v-model="modelProxy" type="radio"
          :value="value"
          :disabled="disabled"
        >
        <slot />
      </label>
    </div>
    <label v-else class="bs-radio inline radio-inline">
      <input
        v-model="modelProxy" type="radio"
        :value="value"
        :disabled="disabled"
      >
      <slot />
    </label>
  </span>
</template>

<script>
import {modelProxy} from '@helpers/vuex'

export default {
  model: {
    prop: 'modelValue',
    event: 'input'
  },
  props: {
    value: {required: true},
    modelValue: {},
    inline: Boolean,
    disabled: Boolean,
    compact: Boolean
  },
  computed: {
    modelProxy
  }
}
</script>

<style lang="scss" scoped>
.bs-radio.inline {
  margin-right: 0.5em;
}
.radio-button {
  margin-top: 3px;
  margin-bottom: 3px;
}
</style>
