<template>
  <div class="feed-amount">
    <div class="feed-amount-inputs">
      <div class="hen-container">
        <span class="very-large-text">
          {{ '♀ ' + SR_PoultryManagement.Hens }}
        </span>
        <span class="large-text">
          {{ SR_PoultryManagement.CurrentLivestock + ': ' + currentPoultryHouse.numHens }}
        </span>
        <div class="hen-input-container">
          <div class="target-today">
            <div><IxRes>FeedTargetToday</IxRes>{{ ':' }}</div>
            <div>{{ (feedAmount.targetToday.gramsPerHen * hens / 1000).toFixed(2) + ' ' + SR_PoultryManagement.unit_kg + ', ' + feedAmount.targetToday.gramsPerHen + ' ' + SR_PoultryManagement.unit_grammPerAnimal }}</div>
            <div><IxRes>Feed</IxRes>{{ ': ' + (feedAmount.targetToday.henFeed || SR_PoultryManagement.noFeed) }}</div>
          </div>
          <div class="target-tomorrow">
            <div><IxRes>FeedTargetTomorrow</IxRes>{{ ':' }}</div>
            <div>{{ (feedAmount.targetTomorrow.gramsPerHen * hens / 1000).toFixed(2) + ' ' + SR_PoultryManagement.unit_kg + ', ' + feedAmount.targetTomorrow.gramsPerHen + ' ' + SR_PoultryManagement.unit_grammPerAnimal }}</div>
            <div><IxRes>Feed</IxRes>{{ ': ' + (feedAmount.targetTomorrow.henFeed || SR_PoultryManagement.noFeed) }}</div>
          </div>
        </div>
        <div class="actual-fed">
          <SimpleSwitch v-model="henInputAsGrammPerAnimal">
            <template #offLabel>
              Gesamtmenge
            </template>
            g/Tier
          </SimpleSwitch>
          <NumericInput
            v-model="henInputWithUnit"
            :rule="x => x >= 0"
            :addon="henUnit"
          >
            <template #label>
              <IxRes>FeedTargetToday</IxRes>:
            </template>
          </NumericInput>
        </div>
        <div v-if="henUnit === 'g'">
          {{ 'Gesamtmenge: ' + totalHenFed.toFixed(2) + ' ' + SR_PoultryManagement.unit_kg }}
        </div>
        <div v-else>
          {{ 'Ca. ' + henFedInGrams.toFixed(2) + ' ' + SR_PoultryManagement.unit_grammPerAnimal }}
        </div>
      </div>
      <div class="rooster-container">
        <span class="very-large-text">
          {{ '♂ ' + SR_PoultryManagement.Roosters }}
        </span>
        <span class="large-text">
          {{ SR_PoultryManagement.CurrentLivestock + ': ' + currentPoultryHouse.numRoosters }}
        </span>
        <div class="hen-input-container">
          <div class="target-today">
            <div><IxRes>FeedTargetToday</IxRes>{{ ':' }}</div>
            <div>{{ (feedAmount.targetToday.gramsPerRooster * roosters / 1000).toFixed(2) + ' ' + SR_PoultryManagement.unit_kg + ', ' + feedAmount.targetToday.gramsPerRooster + ' ' + SR_PoultryManagement.unit_grammPerAnimal }}</div>
            <div><IxRes>Feed</IxRes>{{ ': ' + (feedAmount.targetToday.roosterFeed || SR_PoultryManagement.noFeed) }}</div>
          </div>
          <div class="target-tomorrow">
            <div><IxRes>FeedTargetTomorrow</IxRes>{{ ':' }}</div>
            <div>{{ (feedAmount.targetTomorrow.gramsPerRooster * roosters / 1000).toFixed(2) + ' ' + SR_PoultryManagement.unit_kg + ', ' + feedAmount.targetTomorrow.gramsPerRooster + ' ' + SR_PoultryManagement.unit_grammPerAnimal }}</div>
            <div><IxRes>Feed</IxRes>{{ ': ' + (feedAmount.targetToday.roosterFeed || SR_PoultryManagement.noFeed) }}</div>
          </div>
        </div>
        <div class="actual-fed">
          <SimpleSwitch v-model="roosterInputAsGrammPerAnimal">
            <template #offLabel>
              Gesamtmenge
            </template>
            g/Tier
          </SimpleSwitch>
          <NumericInput
            v-model="roosterInputWithUnit"
            :rule="x => x >= 0"
            :addon="roosterUnit"
          >
            <template #label>
              <IxRes>FeedTargetToday</IxRes>:
            </template>
          </NumericInput>
        </div>
        <div v-if="roosterUnit === 'g'">
          {{ 'Gesamtmenge: ' + totalRoosterFed.toFixed(2) + ' ' + SR_PoultryManagement.unit_kg }}
        </div>
        <div v-else>
          {{ 'Ca. ' + roosterFedInGrams.toFixed(2) + ' ' + SR_PoultryManagement.unit_grammPerAnimal }}
        </div>
      </div>
    </div>
    <div class="water-and-remarks">
      <div class="water-and-remarks-content">
        <div class="water-content">
          <NativeNumericInput
            v-model="waterConsumptionYesterdayInLitres" :description="SR_PoultryManagement.WaterConsumptionYesterday"
            include-colon :addon="SR_PoultryManagement.Litres"
          />
          <span>
            <IxRes>FeedWaterRatioYesterday</IxRes>{{ ': ' + feedWaterRatioYesterday }}
          </span>
        </div>
        <TextInput
          v-model="remarks" multiline
          class="remarks" :rows="6"
          label="Notizen:" placeholder="optional"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import NumericInput from '@components/forms/NumericInput'
import {mapFormFields, mapResources} from '@helpers/vuex'
import SimpleSwitch from '@components/forms/SimpleSwitch'
import TextInput from '@components/forms/TextInput'
import NativeNumericInput from '../components/NativeNumericInput'

import IxRes from '@components/IxRes'

export default {
  translationNamespace: 'Areas.PoultryManagement.SR_PoultryManagement',

  components: {
    IxRes,
    SimpleSwitch,
    NumericInput,
    TextInput,
    NativeNumericInput
  },
  data () {
    return {
      henInputAsGrammPerAnimal: true,
      roosterInputAsGrammPerAnimal: true
    }
  },
  computed: {
    ...mapFormFields('poultryManagement/farmer/feedAmount', [
      'data.henFedInGrams',
      'data.roosterFedInGrams',
      'data.remarks',
      'data.waterConsumptionYesterdayInLitres'
    ]),
    ...mapResources([
      '@pm.SR_PoultryManagement'
    ]),
    ...mapGetters('poultryManagement/farmer', [
      'currentPoultryHouse'
    ]),
    ...mapGetters('poultryManagement/farmer/feedAmount', [
      'feedAmount'
    ]),
    feedWaterRatioYesterday () {
      if (this.waterConsumptionYesterdayInLitres && this.feedAmount.feedAmountYesterdayInKilograms) {
        let dividend = this.feedAmount.feedAmountYesterdayInKilograms / this.waterConsumptionYesterdayInLitres
        return Math.round(dividend) + ' : 1'
      }
      return 'Daten gestern fehlen'
    },
    henUnit () {
      return this.henInputAsGrammPerAnimal ? 'g' : 'kg'
    },
    roosterUnit () {
      return this.roosterInputAsGrammPerAnimal ? 'g' : 'kg'
    },
    hens () {
      return this.currentPoultryHouse.numHens // TODO: use getter for current flock instead
    },
    roosters () {
      return this.currentPoultryHouse.numRoosters // TODO: use getter for current flock instead
    },
    henInputWithUnit: {
      get () {
        return this.henUnit === 'g' ? this.henFedInGrams : this.totalHenFed
      },
      set (amount) {
        this.henFedInGrams = this.henUnit === 'g' ? amount : amount / this.hens * 1000
      }
    },
    totalHenFed () {
      return this.henFedInGrams * this.hens / 1000
    },
    roosterInputWithUnit: {
      get () {
        return this.roosterUnit === 'g' ? this.roosterFedInGrams : this.totalRoosterFed
      },
      set (amount) {
        this.roosterFedInGrams = this.roosterUnit === 'g' ? amount : amount / this.roosters * 1000
      }
    },
    totalRoosterFed () {
      return this.roosterFedInGrams * this.roosters / 1000
    }
  }
}
</script>

<style lang="scss" scoped>
.feed-amount-inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
}

.hen-container {
  padding-right: 10px;
  border-right: 1px solid black;
}

.rooster-container {
  padding-left: 10px;
}

.hen-input-container, .rooster-input-container {
  margin-top: 20px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 2fr 2fr 1fr;
}

.hen-input-container {
  grid-template-areas: "hen-target-today hen-target-tomorrow ."
                       "hen-fed-today . .";
}

.rooster-input-container {
  grid-template-areas: "rooster-target-today rooster-target-tomorrow ."
                       "rooster-fed-today . .";
}

.actual-fed {
  display: grid;
  align-items: end;
  grid-template-areas: "actual-fed fed-per-animal . ";
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.water-and-remarks {
  display: grid;
  grid-template-areas: " . water-and-remarks-content . ";
  grid-template-columns: 1fr 2fr 1fr;
}

.water-and-remarks-content {
  margin-top: 10px;
  grid-area: water-and-remarks-content;
  display: grid;
  grid-template-areas: ". water-content ."
                       "remarks remarks remarks";
  grid-template-columns: 1fr 2fr 1fr;
  grid-gap: 5px;
}

.water-content {
  grid-area: water-content;
}

.remarks {
  grid-area: remarks;
}

.large-text {
  font-size: large;
}

.very-large-text {
  font-size: x-large;
}
</style>
