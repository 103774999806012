<template lang="html">
  <span class="sort-button" :class="{active}" @click="$emit('click')">
    <FaIcon :icon="icon" fixed-width />
  </span>
</template>

<script>
import {FontAwesomeIcon as FaIcon} from '@fortawesome/vue-fontawesome'
import {faSort, faSortDown, faSortUp, faSortNumericDown, faSortNumericUp, faSortAlphaDown, faSortAlphaUp, faSortAmountDown, faSortAmountUp} from '@fortawesome/free-solid-svg-icons'

const sortIcons = {
  string: [faSortAlphaDown, faSortAlphaUp],
  number: [faSortNumericDown, faSortNumericUp],
  amount: [faSortAmountDown, faSortAmountUp]
}

export default {
  components: {
    FaIcon
  },
  props: {
    type: String,
    active: Boolean,
    descending: Boolean
  },
  computed: {
    icon () {
      if (!this.active) return faSort

      const icons = sortIcons[this.type] || [faSortDown, faSortUp]

      return icons[Number(this.descending)]
    }
  }
}
</script>

<style lang="scss" scoped>
.sort-button {
  cursor: pointer;

  &:not(.active) {
    color: #bbb;
  }
}
</style>
