<template>
  <div class="poultry-house-management">
    <div class="back-link">
      <i class="fa fa-arrow-circle-o-left" />
      <a @click="goToFarm">
        <IxRes>GoBackToFarmOverview</IxRes>
      </a>
    </div>
    <div class="poultry-house-management-content">
      <div class="left-column">
        <div>
          <div class="occupation-container">
            <div class="occupation-container-stats">
              <IxRes>PoultryHouse</IxRes>:
              <span>{{ getStableNumber }}</span>
              <i class="fa fa-venus" />
              <span>{{ getNumberOfFemales }}</span>
              <i class="fa fa-mars" />
              <span>{{ getNumberOfMales }}</span>
            </div>
            <div v-if="getFlocks.length">
              <IxRes>flocks</IxRes>:
              <ul>
                <li v-for="(flock, idx) in getFlocks" :key="idx">
                  {{ flock }}
                </li>
              </ul>
            </div>
          </div>
          <div>
            <h4>
              <IxRes>SetGenderSpecificAmountOfFeed</IxRes>
            </h4>
            <div class="pm-flex-row">
              <DatePicker v-model="genderSpecificFoodDate" />
              <IxRes>fromThe</IxRes>
              <NumberInput v-model="dayOfLiving" />
              <IxRes>DayOfLife</IxRes>
            </div>
            <SimpleTable :data="sortedFeed" :columns="getFeedColumns" localize-headers>
              <template slot="timeRange" slot-scope="{rowIndex, value, row}">
                <template v-if="isFeedRowInEditMode(rowIndex)">
                  <DatePicker
                    :value="value"
                    @input="updateTimeRange({item: row, timeRange: $event})"
                  />
                </template>
                <template v-else>
                  {{ value | formatDate }}
                </template>
              </template>

              <template slot="amountFemale" slot-scope="{rowIndex, value, row}">
                <template v-if="isFeedRowInEditMode(rowIndex)">
                  <NumberInput
                    :value="value"
                    @input="updateAmountFemale({item: row, newAmount: $event})"
                  />
                </template>
                <template v-else>
                  {{ value }}
                </template>
              </template>

              <template slot="amountMale" slot-scope="{rowIndex, value, row}">
                <template v-if="isFeedRowInEditMode(rowIndex)">
                  <NumberInput
                    :value="value"
                    @input="updateAmountMale({item: row, newAmount: $event})"
                  />
                </template>
                <template v-else>
                  {{ value }}
                </template>
              </template>

              <template slot="actions" slot-scope="{rowIndex, row}">
                <a @click="switchFeedView(rowIndex)">
                  <i v-if="isFeedRowInEditMode(rowIndex)" class="fa fa-check" />
                  <i v-else class="fa fa-pencil" />
                </a>
                <a @click="deleteFeed(row)">
                  <i class="fa fa-trash" />
                </a>
              </template>
            </SimpleTable>
            <a @click="addFeed">
              <IxRes>addFeed</IxRes>
            </a>
          </div>
          <div>
            <h4>
              <IxRes>dailyRecords</IxRes>
            </h4>
            {{ tillYesterdayMessage }}
            <ol v-if="getDailyMessages.length !== 0">
              <li v-for="dailyMessage in getDailyMessages" :key="dailyMessage.id">
                {{ dailyMessage.date }}
              </li>
            </ol>
          </div>
          <div>
            <h4>
              <IxRes>useofAntiboticas</IxRes>
            </h4>
            <div v-if="antiboticasAreUsed">
              <i class="fa fa-check-square-o" />
              <IxRes>antiboticasAreUsedInThisPoultryHouse</IxRes>
            </div>
            <div v-else>
              <i class="fa fa-square-o" />
              <IxRes>antiboticasAreNotUsedInThisPoultryHouse</IxRes>
            </div>
            <SimpleTable :data="getAntibioticas" :columns="getAntiboticasColumns" localize-headers>
              <template slot="from" slot-scope="{rowIndex, value, row}">
                <template v-if="isAntibioticaRowInEditMode(rowIndex)">
                  <DatePicker
                    :value="value"
                    @input="updateFromDate({item: row, from: $event})"
                  />
                </template>
                <template v-else>
                  {{ value.format('L') }}
                </template>
              </template>
              <template slot="to" slot-scope="{rowIndex, value, row}">
                <template v-if="isAntibioticaRowInEditMode(rowIndex)">
                  <DatePicker
                    :value="value"
                    @input="updateToDate({item: row, to: $event})"
                  />
                </template>
                <template v-else>
                  {{ value.format('L') }}
                </template>
              </template>
              <template slot="actions" slot-scope="{row, rowIndex}">
                <a @click="switchAntibioticaView(rowIndex)">
                  <i v-if="isAntibioticaRowInEditMode(rowIndex)" class="fa fa-check" />
                  <i v-else class="fa fa-pencil" />
                </a>
                <a @click="deleteAntiboticaUse(row)">
                  <i class="fa fa-trash" />
                </a>
              </template>
            </SimpleTable>
            <a @click="addUseOfAntibiotica">
              <IxRes>addTimePeriod</IxRes>
            </a>
          </div>
        </div>
      </div>
      <div class="right-column">
        <fieldset>
          <legend><IxRes>contact</IxRes></legend>
          <TextInput v-model="contactName">
            <template slot="label">
              <IxRes>name</IxRes>
            </template>
          </TextInput>
          <TextInput v-model="contactPhone">
            <template slot="label">
              <IxRes>phone</IxRes>
            </template>
          </TextInput>
        </fieldset>
      </div>
    </div>
    <div class="button-container">
      <IxButton save large @click="submitPoultryHouse" />
      <IxButton cancel large @click="reset" />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {mapMutations, mapGetters, mapActions} from 'vuex'

import {mapResources} from '@helpers/vuex'
import IxButton from '@components/IxButton'
import IxRes from '@components/IxRes'
import TextInput from '@components/forms/TextInput'
import NumberInput from '@components/forms/NumericInput'
import DatePicker from '@components/forms/VueDateTimePicker'
import SimpleTable from '@components/table/SimpleTable'

import {notifications} from 'src/js/infrastructure'

const compareMoment = (left, right) => left.isSame(right) ? 0 : left.isBefore(right) ? -1 : 1

export default {
  translationNamespace: 'Areas.PoultryManagement.SR_PoultryManagement',

  components: {
    IxButton,
    IxRes,
    TextInput,
    DatePicker,
    SimpleTable,
    NumberInput
  },

  filters: {
    formatDate (value) {
      return moment(value).format('L')
    }
  },

  data () {
    return {
      antibioticaEditingIndex: null,
      feedEditingIndex: null
    }
  },

  computed: {
    ...mapGetters('poultryManagement/productionManager/poultryHouseManagement', [
      'getFeedColumns',
      'getFeeds',
      'getContactName',
      'getContactPhone',
      'getStableNumber',
      'getNumberOfFemales',
      'getNumberOfMales',
      'getDailyMessages',
      'getFlocks',
      'getAntiboticasColumns',
      'getAntibioticas',
      'getDayOfLiving',
      'getGenderSpecificFoodDate'
    ]),

    ...mapResources([
      'Areas.PoultryManagement.SR_PoultryManagement',
      'Messages.State.SR_StateMessages'
    ]),

    genderSpecificFoodDate: {
      get () { return this.getGenderSpecificFoodDate },
      set (value) { this.setGenderSpecificFoodDate(value) }
    },

    dayOfLiving: {
      get () { return this.getDayOfLiving },
      set (value) { this.setDayOfLiving(value) }
    },

    contactName: {
      get () { return this.getContactName },
      set (value) { this.setContactName(value) }
    },

    contactPhone: {
      get () { return this.getContactPhone },
      set (value) { this.setContactPhone(value) }
    },

    sortedFeed () {
      return this.getFeeds
        .slice()
        .sort((left, right) => compareMoment(right.timeRange, left.timeRange))
    },
    tillYesterdayMessage () {
      return 'Seit gestern (' + moment().subtract(1, 'day').format('L') + ') ' + 'keine fehlende Tagesmeldung'
    },
    antiboticasAreUsed () {
      return this.getAntibioticas.some(row => moment().isBetween(row.from, row.to, 'day', '[]'))
    },

    isFeedRowInEditMode () {
      return (rowIndex) => rowIndex === this.feedEditingIndex
    },

    isAntibioticaRowInEditMode () {
      return (rowIndex) => rowIndex === this.antibioticaEditingIndex
    }
  },

  methods: {
    ...mapMutations('poultryManagement/productionManager', [
      'goToPage'
    ]),

    ...mapMutations('poultryManagement/productionManager/poultryHouseManagement', [
      'setContactName',
      'setContactPhone',
      'addUseOfAntibiotica',
      'deleteAntiboticaUse',
      'updateFromDate',
      'updateToDate',
      'addFeed',
      'deleteFeed',
      'updateTimeRange',
      'updateAmountFemale',
      'updateAmountMale',
      'setGenderSpecificFoodDate',
      'setDayOfLiving'
    ]),

    ...mapActions('poultryManagement/productionManager/poultryHouseManagement', [
      'submit'
    ]),
    submitPoultryHouse () {
      this.submit()
        .then(() => {
          notifications.success(this.SR_StateMessages.SaveSuccess)
        })
        .catch(error => {
          notifications.error(this.SR_StateMessages.SaveError)
          console.error(error)
        })
    },

    reset () {
      this.goToPage('Overview')
    },

    goToFarm () {
      this.goToPage('Overview')
    },

    switchFeedView (rowIndex) {
      if (this.feedEditingIndex === rowIndex) {
        this.feedEditingIndex = null
      } else {
        this.feedEditingIndex = rowIndex
      }
    },

    switchAntibioticaView (rowIndex) {
      if (this.antibioticaEditingIndex === rowIndex) {
        this.antibioticaEditingIndex = null
      } else {
        this.antibioticaEditingIndex = rowIndex
      }
    }
  }
}

</script>

<style lang="scss" scoped>
  .poultry-house-management {

    display: grid;
    grid: auto 1fr auto / auto;
    height: 100%;

    .poultry-house-management-content {

      display: grid;
      grid: auto / 2fr 1fr;
      height: 100%;

      h4 {
        margin-top: 30px;
      }

      table a:not(:last-child) {
        margin-right: 6px;
      }

      .left-column {

        position: relative;
        margin-right: 20px;
        padding: 10px;

        & > div {
          overflow-y: auto;
          position: absolute;
          top: 0px;
          bottom: 0px;
          left: 0px;
          right: 0px;
        }
      }
    }

    .table {
      margin: {
        bottom: 5px;
      }
    }

    .pm-flex-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: {
        bottom: 10px;
      }

      & > * {
        margin: {
          right: 10px;
        }
      }
    }

    .button-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      & > :not(:first-child) {
        margin-left: 10px;
      }
    }

    .occupation-container {

      padding: 10px 0;

      .occupation-container-stats {

        font-size: 1.2em;

        > i {
          margin: 0 3px 0 6px;
        }
      }
    }
  }
</style>
