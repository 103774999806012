<template>
  <div class="feed-management-container">
    <a @click="back">
      <IxRes>GoBackToFarmOverview</IxRes>
    </a>
    <SimpleTable :data="allFeeds" :columns="columns" localize-headers>
      <template slot="isFavorite" slot-scope="{value}">
        <span class="fa" :class="value ? 'fa-star' : 'fa-star-o'" />
      </template>
    </SimpleTable>
  </div>
</template>

<script>

import SimpleTable from '@components/table/SimpleTable'
import {mapMutations, mapGetters} from 'vuex'

export default {
  translationNamespace: 'Areas.PoultryManagement.SR_PoultryManagement',

  components: {
    SimpleTable
  },

  computed: {
    ...mapGetters('poultryManagement/productionManager', [
      'allFeeds'
    ]),
    columns () {
      return {
        isFavorite: {title: '', sortable: false},
        name: {title: 'Areas.PoultryManagement.SR_PoultryManagement.Type', sortable: true},
        producer: {title: 'Areas.PoultryManagement.SR_PoultryManagement.Vendor', sortable: true},
        targetGroup: {title: 'Areas.PoultryManagement.SR_PoultryManagement.TargetGroup', sortable: true},
        manageFeed: {title: '', sortable: false}
      }
    }
  },

  methods: {
    ...mapMutations('poultryManagement/productionManager', [
      'goToPage'
    ]),

    back () {
      this.goToPage('Overview')
    }
  }
}

</script>

<style lang="scss">
  .feed-management-container {
    display: flex;
    flex-direction: column;

    &>*{
      margin-bottom: 10px;
    }

  }
</style>
