<template lang="html">
  <div v-if="initComplete" class="poultry-management">
    <ProductionManager v-if="currentView === 'productionManager'" />
    <Farmer v-else-if="currentView === 'farmer'" />
    <div v-else>
      Fehler!!!!
    </div>
    <TranslationControls :always-show="permissions.includes('frs-translator')" />
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations, mapGetters} from 'vuex'

import TranslationControls from '@components/translation/TranslationControls'
import SelfDestruct from '@mixins/SelfDestruct'
import ProductionManager from './production-manager/ProductionManager'
import Farmer from './farmer/Farmer'

export default {
  components: {
    TranslationControls,
    Farmer,
    ProductionManager
  },
  mixins: [
    SelfDestruct
  ],
  computed: {
    ...mapState('poultryManagement', [
      'initComplete',
      'permissions'
    ]),

    ...mapGetters('poultryManagement', [
      'currentView',
      'hasProductionManagerPermission'
    ])
  },
  methods: {
    ...mapActions('poultryManagement', [
      'loadPermissions',
      'loadPermissionsNg'
    ]),
    ...mapActions('poultryManagement/navigation', [
      'loadTree'
    ]),
    ...mapMutations('poultryManagement', [
      'setInitComplete',
      'goToView'
    ])
  },
  async created () {
    this.setInitComplete(false)

    await this.loadPermissionsNg()
    await this.loadPermissions()

    await this.$store.dispatch('i18n/preloadTranslations', [
      'Common.SR_Common',
      'Messages.Info.SR_InfoMessages',
      'Messages.State.SR_StateMessages',
      'Areas.PoultryManagement.SR_PoultryManagement',
      'Areas.FieldRecordSystem.SR_FieldRecordSystem' // TODO: needed, because there is a frs resource in RocketHeader.vue,
    ])

    var params = new URLSearchParams(window.location.search)
    if ((params.has('view') && params.get('view') === 'farmer') || !this.hasProductionManagerPermission) {
      this.goToView('farmer')
    }

    await this.loadTree()

    this.setInitComplete(true)
  }
}
</script>

<style lang="scss" scoped>

.poultry-management{
  width: 100%;
  height: 100%;
  max-height: 100%;
}

</style>
