<template>
  <div class="pwt-container">
    <div class="pwt-headline">
      <div />
      <div>
        <span class="large-text">
          <IxRes>Farm</IxRes> {{ currentFarmName || ' ' }}
          <IxRes>PoultryHouse</IxRes>: {{ currentPoultryHouseNumber || ' ' }}
          <span v-if="currentPage==='PoultryHouseVisit'">
            <IxRes>Visit</IxRes>: {{ currentVisit.run }}
          </span>
          <span v-else>
            <IxRes>{{ currentPage }}</IxRes>
          </span>
        </span>
      </div>
      <div>
        <!--<span @click="onSaveAndDayBack">
          <i class="fa fa-chevron-left" />
          {{ ' ' }}
          <IxRes>SaveAndGoBackOneDay</IxRes>
          {{ ' ' }}
        </span>-->
        <input v-model="selectedDate" type="date">
      </div>
    </div>
    <component :is="currentPage" class="pwt-content" />
    <div class="pwt-buttons">
      <!--<a class="save-and-previous" @click="onSaveAndPrevious">
        <i class="fa fa-chevron-left" />
        <IxRes>SaveAndGotoPreviousPoultryHouse</IxRes>
      </a>-->
      <span class="save">
        <IxButton save large @click="onSave">
          <IxRes>{{ saveButtonResource }}</IxRes>
        </IxButton>
      </span>
      <!--<a class="save-and-next" @click="onSaveAndNext">
        <IxRes>SaveAndGotoNextPoultryHouse</IxRes>
        <i class="fa fa-chevron-right" />
      </a>-->
      <span class="cancel">
        <IxButton cancel large @click="onCancel" />
      </span>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
import {mapFormFields} from '@helpers/vuex'

import IxButton from '@components/IxButton'
import AnimalWeight from './AnimalWeight'
import EggProduction from './EggProduction'
import FeedAmount from './FeedAmount'
import PoultryHouseVisit from './PoultryHouseVisit'

export default {
  translationNamespace: 'Areas.PoultryManagement.SR_PoultryManagement',

  components: {
    IxButton,
    AnimalWeight,
    EggProduction,
    FeedAmount,
    PoultryHouseVisit
  },

  data () {
    return {
    }
  },
  computed: {
    ...mapFormFields('poultryManagement/farmer', [
      'data.selectedDate'
    ]),
    ...mapGetters('poultryManagement/navigation', [
      'tree'
    ]),
    ...mapGetters('poultryManagement/farmer', [
      'currentPage',
      'currentFarmName',
      'currentPoultryHouseNumber',
      'currentVisit'
    ]),
    saveButtonResource () {
      switch (this.currentPage) {
      case 'PoultryHouseVisit' : return 'SaveVisit'
      case 'AnimalWeight' : return 'SaveAnimalWeight'
      case 'FeedAmount' : return 'SaveFeedAmount'
      default: return 'Save'
      }
    }
  },
  methods: {
    ...mapMutations('poultryManagement/farmer', [
      'goToPage'
    ]),
    ...mapActions('poultryManagement/farmer', [
      'saveVisit'
    ]),
    ...mapActions('poultryManagement/farmer/animalWeight', [
      'saveAnimalWeight'
    ]),
    ...mapActions('poultryManagement/farmer/feedAmount', [
      'saveFeedAmount'
    ]),
    ...mapActions('poultryManagement/farmer/eggProduction', [
      'saveEggProduction'
    ]),
    onSaveAndDayBack () {
    },
    onSave () {
      switch (this.currentPage) {
      case 'PoultryHouseVisit' : {
        this.saveVisit()
        break
      }
      case 'AnimalWeight' : {
        this.saveAnimalWeight()
        break
      }
      case 'FeedAmount' : {
        this.saveFeedAmount()
        break
      }
      case 'EggProduction': {
        this.saveEggProduction()
        break
      }
      default: throw new Error('no action for saving specified')
      }
    },
    onCancel () {
      this.goToPage('Overview')
    },
    onSaveAndNext () {
    },
    onSaveAndPrevious () {
    }
  }
}
</script>

<style lang="scss" scoped>
.pwt-container {
  display: grid;
  row-gap: 10px;
  grid-template-rows: 40px auto 80px;
}

.pwt-headline {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
}

.pwt-buttons {
  display: grid;
  grid-gap: 10px;
  grid-template-areas: "save-and-previous . save-and-next "
                       "save . cancel ";
}

.save-and-previous {
  grid-area: save-and-previous;
}

.save-and-next {
  grid-area: save-and-next;
}

.save-and-previous, .save {
  width: fit-content;
  justify-self: end;
}

.save {
  grid-area: save;
}

.cancel {
 grid-area: cancel;
}

.large-text {
  font-size: large;
}
</style>
