import SimpleTableBoolean from './SimpleTableBoolean'
import SimpleTableStringArray from './SimpleTableStringArray'
import {format} from 'src/js/i18n/conversion'

export default {
  props: {
    value: {},
    type: String,
    formatString: String
  },
  functional: true,
  render (h, context) {
    const {value, type, formatString} = context.props

    if (Array.isArray(value)) {
      return h(SimpleTableStringArray, {props: {value, type}})
    } else if (typeof value === 'boolean' || type === 'boolean') {
      return h(SimpleTableBoolean, {props: {value}})
    } else if (value !== null && (typeof value === 'number' || type === 'number')) {
      return h('span', format(value, formatString))
    } else if (value !== null && (type === 'date' || type === 'datetime')) {
      return h('span', format(value, type))
    } else if (value && value.name) {
      return h('span', value.name)
    } else {
      return h('span', (value !== null && value !== undefined) ? value.toString() : '')
    }
  }
}
