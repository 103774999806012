<template>
  <div>
    <div class="animal-weight-inputs">
      <div class="hen-container">
        <span class="very-large-text">
          {{ '♀ ' + SR_PoultryManagement.Hens }}
        </span>
        <span class="large-text">
          {{ SR_PoultryManagement.CurrentLivestock + ': ' + currentPoultryHouse.numHens }}
        </span>
        <div class="hen-input-container">
          <NativeNumericInput
            v-model="henAverage" :description="SR_PoultryManagement.AverageWeight"
            include-colon class="hen-average"
            addon="g"
          />
          <NativeNumericInput
            v-model="henUniformity" :description="SR_PoultryManagement.Uniformity"
            include-colon class="hen-uniformity"
            addon="%"
          />
          <NativeNumericInput
            v-model="henNumberWeighed" :description="SR_PoultryManagement.NumberWeighedAnimals"
            class="hen-number-weighed" include-colon
          />
          <!-- <span class="hen-covariance"><IxRes>CovarianceOfAnimalWeights</IxRes> {{ ': ' + henCovariance }}</span> -->
        </div>
      </div>
      <div class="rooster-container">
        <span class="very-large-text">
          {{ '♂ ' + SR_PoultryManagement.Roosters }}
        </span>
        <span class="large-text">
          {{ SR_PoultryManagement.CurrentLivestock + ': ' + currentPoultryHouse.numRoosters }}
        </span>
        <div class="rooster-input-container">
          <NativeNumericInput
            v-model="roosterAverage" :description="SR_PoultryManagement.AverageWeight"
            include-colon class="rooster-average"
            addon="g"
          />
          <NativeNumericInput
            v-model="roosterUniformity" :description="SR_PoultryManagement.Uniformity"
            include-colon class="rooster-uniformity"
            addon="%"
          />
          <NativeNumericInput
            v-model="roosterNumberWeighed" :description="SR_PoultryManagement.NumberWeighedAnimals"
            class="rooster-number-weighed" include-colon
          />
          <!-- <span class="rooster-covariance"><IxRes>CovarianceOfAnimalWeights</IxRes> {{ ': ' + henCovariance }}</span> -->
        </div>
      </div>
    </div>
    <div class="remarks-container">
      <TextInput
        v-model="remarks" multiline
        class="remarks-input" :rows="8"
        label="Notizen:" placeholder="optional"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {mapFormFields, mapResources} from '@helpers/vuex'

// import IxRes from '@components/IxRes'
import TextInput from '@components/forms/TextInput'
import NativeNumericInput from '../components/NativeNumericInput'

export default {
  translationNamespace: 'Areas.PoultryManagement.SR_PoultryManagement',

  components: {
    // IxRes,
    NativeNumericInput,
    TextInput
  },
  data () {
    return {
      // henCovariance: 0,
      // roosterCovariance: 0
    }
  },
  computed: {
    ...mapResources([
      '@pm.SR_PoultryManagement'
    ]),
    ...mapFormFields('poultryManagement/farmer/animalWeight', [
      'data.henAverage',
      'data.henUniformity',
      'data.henNumberWeighed',
      'data.roosterAverage',
      'data.roosterUniformity',
      'data.roosterNumberWeighed',
      'data.remarks'
    ]),
    ...mapGetters('poultryManagement/farmer', [
      'currentPoultryHouse'
    ])
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.animal-weight-inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
}

.hen-container {
  padding-right: 10px;
  border-right: 1px solid black;
}

.rooster-container {
  padding-left: 10px;
}

.hen-input-container, .rooster-input-container {
  margin-top: 20px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
}

.hen-input-container {
  grid-template-areas: "hen-average hen-uniformity ."
                       "hen-number-weighed . .";
                      //  "hen-covariance . .";
}

.hen-average {
  grid-area: hen-average;
}

.hen-uniformity {
  grid-area: hen-uniformity;
}

.hen-number-weighed {
  grid-area: hen-number-weighed;
}

.hen-covariance {
  grid-area: hen-covariance;
}

.rooster-input-container {
  grid-template-areas: "rooster-average rooster-uniformity ."
                       "rooster-number-weighed . .";
                      //  "rooster-covariance . .";
}

.rooster-average {
  grid-area: rooster-average;
}

.rooster-uniformity {
  grid-area: rooster-uniformity;
}

.rooster-number-weighed {
  grid-area: rooster-number-weighed;
}

.rooster-covariance {
  grid-area: rooster-covariance;
}

.remarks-container {
  display: grid;
  margin-top: 20px;
  grid-template-areas: ". remarks-input .";
}

.remarks-input {
  grid-area: remarks-input;
}

.large-text {
  font-size: large;
}

.very-large-text {
  font-size: x-large;
}

</style>
