<template>
  <div class="panel-content">
    <span class="very-large-text">
      {{ genderText }}
    </span>
    <span class="large-text">
      {{ SR_PoultryManagement.CurrentLivestock + ': ' + livestock }}
    </span>
    <p class="large-text">
      <IxRes>Livestock</IxRes>
    </p>
    <p>Verluste</p>
    <input v-model="currentVisit[genderIsHen ? 'henLosses' : 'roosterLosses']" type="number" :disabled="disabled">
    <p class="large-text">
      Selektion
    </p>
    <div class="input-box">
      <div class="input-with-label">
        <IxRes>Emaciation</IxRes>
        <input v-model="currentVisit[genderIsHen ? 'henEmaciation' : 'roosterEmaciation']" type="number" :disabled="disabled">
      </div>
      <div class="input-with-label">
        <IxRes>Apathie</IxRes>
        <input v-model="currentVisit[genderIsHen ? 'henApathy' : 'roosterApathy']" type="number" :disabled="disabled">
      </div>
      <div class="input-with-label">
        <IxRes>LegDamage</IxRes>
        <input v-model="currentVisit[genderIsHen ? 'henLegDamage' : 'roosterLegDamage']" type="number" :disabled="disabled">
      </div>
      <div class="input-with-label">
        <IxRes>Other</IxRes>
        <input v-model="currentVisit[genderIsHen ? 'henOther' : 'roosterOther']" type="number" :disabled="disabled">
      </div>
      <div>
        <IxRes>LossesAndSelection</IxRes>: <span class="bold-text"> {{ total }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import IxRes from '@components/IxRes'

import {mapGetters} from 'vuex'
import {mapResources} from '@helpers/vuex'

export default {
  translationNamespace: 'Areas.PoultryManagement.SR_PoultryManagement',

  components: {
    IxRes
  },
  props: {
    genderIsHen: Boolean,
    disabled: Boolean
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapResources([
      '@pm.SR_PoultryManagement'
    ]),
    ...mapGetters('poultryManagement/farmer', [
      'currentVisit'
    ]),

    losses: {
      get () {
        return this.currentVisit[this.genderIsHen ? 'henLosses' : 'roosterLosses']
      },
      set (value) {
        this.updateLosses(this.genderIsHen ? 'henLosses' : 'roosterLosses', value)
      }
    },

    genderText () {
      return this.genderIsHen
        ? '♀ ' + this.SR_PoultryManagement.Hens
        : '♂ ' + this.SR_PoultryManagement.Roosters
    },
    livestock () {
      return this.genderIsHen
        ? this.currentVisit.hens
        : this.currentVisit.roosters
    },
    total () {
      if (this.genderIsHen) {
        return parseInt(this.currentVisit.henLosses) +
          parseInt(this.currentVisit.henEmaciation) +
          parseInt(this.currentVisit.henApathy) +
          parseInt(this.currentVisit.henLegDamage) +
          parseInt(this.currentVisit.henOther)
      } else {
        return parseInt(this.currentVisit.roosterLosses) +
          parseInt(this.currentVisit.roosterEmaciation) +
          parseInt(this.currentVisit.roosterApathy) +
          parseInt(this.currentVisit.roosterLegDamage) +
          parseInt(this.currentVisit.roosterOther)
      }
    }
  },
  watch: {
    disabled (isDisabled) {
      if (isDisabled) {
        if (this.genderIsHen) {
          this.currentVisit.henLosses = 0
          this.currentVisit.henEmaciation = 0
          this.currentVisit.henApathy = 0
          this.currentVisit.henLegDamage = 0
          this.currentVisit.henOther = 0
        } else {
          this.currentVisit.roosterLosses = 0
          this.currentVisit.roosterEmaciation = 0
          this.currentVisit.roosterApathy = 0
          this.currentVisit.roosterLegDamage = 0
          this.currentVisit.roosterOther = 0
        }
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.panel-content {
  width: 100%;
  height: 100%;
}

.bold-text {
  font-weight: bold;
}

.input-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}

.large-text {
  font-size: large;
}

.very-large-text {
  font-size: x-large;
}

.input-with-label {
  display: flex;
  flex-direction: column;
}

</style>
