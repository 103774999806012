<template>
  <div class="production-manager-view">
    <div class="left-container">
      <div class="tree-container">
        <RocketNav
          v-model="selectedEntity"
          :current-org="orgUnit"
          :parent-org="parent"
          :items="entities"
        >
          <template #farm="{item, selected}">
            <FarmCard
              :farm="item"
            />
          </template>
        </RocketNav>
      </div>
      <div class="button-container">
        <IxButton
          large
          icon="exchange"
          :disabled="!isFarm"
          @click="switchToFarmView"
        >
          <IxRes>SwitchToFarmerView</IxRes>
        </IxButton>
        <IxButton
          large
          disabled
          icon="cog"
          @click="goToGeneralSettings"
        >
          <IxRes>GoToGeneralSettings</IxRes>
        </IxButton>
      </div>
    </div>
    <div v-if="displayMainContent" class="main-content">
      <transition name="component-fade" mode="out-in">
        <component :is="currentPage" />
      </transition>
    </div>
    <bs-modal v-model="showModal" blocking>
      <div>
        Sie haben aktuell keine Berechtigung im System, diese Farm zu sehen. Bitte wenden Sie sich an Frank Wiedenroth.
      </div>
      <template #footer>
        <button class="btn btn-success" @click="showModal = false">
          Schließen
        </button>
      </template>
    </bs-modal>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'
import IxButton from '@components/IxButton'
import RocketNav from '@components/nav/RocketNav'
import BsModal from '@components/bootstrap/BsModal'

import Overview from './Overview'
import Error from './Error'
import InstableFlock from './InstableFlock'
import OutstableFlock from './OutstableFlock'
import FarmCard from '../components/FarmCard'
import FlockManagement from './FlockManagement'
import FeedManagement from './FeedManagement'
import PoultryHouseManagement from './PoultryHouseManagement'

export default {
  translationNamespace: 'Areas.PoultryManagement.SR_PoultryManagement',

  components: {
    IxButton,
    RocketNav,
    BsModal,
    Overview,
    Error,
    InstableFlock,
    OutstableFlock,
    FarmCard,
    FlockManagement,
    FeedManagement,
    PoultryHouseManagement
  },

  data () {
    return {
      selectedEntity: null,
      displayMainContent: false,
      showModal: false
    }
  },
  computed: {
    ...mapState('poultryManagement', {
      permissionsNg: state => state.permissionsNg
    }),
    ...mapGetters('poultryManagement/navigation', [
      'tree',
      'entityLookup',
      'parentLookup'
    ]),
    ...mapGetters('poultryManagement/productionManager', [
      'currentPage',
      'currentFarm',
      'currentOrgUnit'
    ]),
    orgUnit () {
      if (this.selectedEntity) {
        return this.entityLookup[this.selectedEntity]
      } else return null
    },
    entities () {
      if (this.selectedEntity) {
        return this.entityLookup[this.selectedEntity].children || []
      } else return this.tree
    },
    parent () {
      if (this.selectedEntity) {
        return this.entityLookup[this.parentLookup[this.selectedEntity]]
      } else return {}
    },
    hasFarmViewPermission () {
      if (!this.permissionsNg[this.selectedEntity]) return false
      if (!this.permissionsNg[this.selectedEntity].includes('FarmerView')) return false
      return true
    },
    isFarm () {
      return this.orgUnit && this.orgUnit.type === 'farm'
    }
  },

  methods: {
    ...mapActions('poultryManagement', [
      'checkRoleAndGotoFarm',
      'loadBreeds'
    ]),
    ...mapActions('poultryManagement/productionManager', [
      'openFarmAsFarmer'
    ]),
    ...mapMutations('poultryManagement', [
      'goToView'
    ]),
    ...mapMutations('poultryManagement/productionManager', [
      'setCurrentFarm',
      'setCurrentOrgUnit'
    ]),
    ...mapMutations('poultryManagement/farmer', [
      'setCurrentFarm'
    ]),
    switchToFarmView () {
      if (this.hasFarmViewPermission) {
        this.openFarmAsFarmer()
      } else {
        this.showModal = true
      }
    },
    goToGeneralSettings () { }
  },
  watch: {
    orgUnit (orgUnit) {
      if (orgUnit && orgUnit.type === 'farm') {
        this.displayMainContent = true
        this.checkRoleAndGotoFarm(orgUnit)
      } else {
        this.setCurrentOrgUnit(orgUnit)
        this.setCurrentFarm(null)
        this.displayMainContent = false
      }
    }
  },
  mounted () {
    this.loadBreeds()
  }
}

</script>

<style lang="scss" scoped>

.production-manager-view {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: grid;
  grid: 1fr / 3fr 5fr;
  & > div {
    padding: {
      top: 10px;
      right: 10px;
      left: 10px;
      bottom: 0px;
    }
  }

  .left-container {
    border-right: 1px solid dimgray;
    margin: 0 0 0 auto;
    height: 100%;
    display: grid;
    grid: auto min-content / 1fr;
  }

  .main-content {
    .component-fade-enter-active, .component-fade-leave-active {
      transition: opacity .3s ease;
    }

    .component-fade-enter, .component-fade-leave-to{
      opacity: 0;
    }
  }
}
</style>
