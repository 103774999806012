<template>
  <div class="main-content">
    Diese Funktion wurde noch nicht entwickelt
  </div>
</template>

<script>
// import IxButton from '@components/IxButton'
import {mapGetters, mapMutations, mapActions} from 'vuex'

export default {
  translationNamespace: 'Areas.PoultryManagement.SR_PoultryManagement',

  components: {
    // IxButton,
  },

  data () {
    return {
    }
  },
  computed: {
    ...mapGetters('poultryManagement/navigation', [
    ]),
    ...mapGetters('poultryManagement/farmer', [
    ])
  },

  methods: {
    ...mapMutations('poultryManagement', [
    ]),
    ...mapActions('poultryManagement/farmer', [
    ])
  }
}

</script>

<style lang="scss" scoped>

</style>
