export default {
  mounted () {
    const mutationOptions = {
      childList: true,
      subtree: true
    }
    const mutationCallback = mutationList => {
      for (const mutation of mutationList) {
        for (const node of mutation.removedNodes) {
          if (node === this.$el && !this.$el.parentNode) {
            this.$destroy()
          }
        }
      }
    }

    this.observer = new MutationObserver(mutationCallback)
    this.$nextTick().then(() => this.observer.observe(document.body, mutationOptions))
  },
  beforeDestroy () {
    if (this.observer) {
      this.observer.disconnect()
    }
  }
}
