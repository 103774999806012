<template>
  <div class="feed-recommendation">
    <p>{{ title }}</p>
    <SimpleTable :data="internalFeeds" :columns="columns" localize-headers>
      <template #timeRange="{row, value, rowIndex}">
        <div v-if="row.isEditMode">
          <input
            type="number" :value="row.start"
            class="time-range-input"
            @input="update(rowIndex, 'start', $event.target.valueAsNumber)"
          >
          <span> - </span>
          <input
            type="number"
            :value="row.end"
            class="time-range-input"
            @input="update(rowIndex, 'end', $event.target.valueAsNumber)"
          >
        </div>
        <span v-else> {{ row.start }} - {{ row.end }} </span>
      </template>
      <template #feed="{row, value, rowIndex}">
        <VueSelectize
          v-if="row.isEditMode"
          :value="row.productNumber"
          :options="allFeeds"
          :fields="{text: 'name', value: 'productNumber'}"
          @input="update(rowIndex, 'feed', $event)"
        />
        <template v-else>
          <span v-if="row.name">{{ row.name }}</span>
          <IxRes v-else>NoEntry</IxRes>
        </template>
      </template>
      <template #buttons="{row}">
        <IxButton v-if="row.isEditMode" icon="check" @click="endEdit(row)" />
        <IxButton v-else icon="edit" @click="beginEdit(row)" />
        <IxButton icon="trash" @click="remove(row)" />
      </template>
    </SimpleTable>
    <a @click="addFeed"><IxRes>AddFeed</IxRes></a>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

import {mapResources} from '@helpers/vuex'

import IxButton from '@components/IxButton'
import VueSelectize from '@components/VueSelectize'

import SimpleTable from '@components/table/SimpleTable'

export default {
  translationNamespace: 'Areas.PoultryManagement.SR_PoultryManagement',

  components: {
    IxButton,
    SimpleTable,
    VueSelectize
  },
  props: {
    feeds: Array,
    gender: String
  },
  data () {
    return {
      internalFeeds: [],
      defaultAdditionalDays: 30
    }
  },
  computed: {
    ...mapGetters('poultryManagement/productionManager', [
      'allFeeds',
      'currentFlockDaysOfLife'
    ]),
    ...mapResources([
      'Areas.PoultryManagement.SR_PoultryManagement'
    ]),
    columns () {
      return {
        timeRange: {title: 'Areas.PoultryManagement.SR_PoultryManagement.TimeRangeDays', sortable: false},
        feed: {title: 'Areas.PoultryManagement.SR_PoultryManagement.NameOfFood', sortable: false},
        buttons: {title: ''}
      }
    },
    title () {
      return this.gender === 'hen' ? this.SR_PoultryManagement.HensWithSymbol : this.SR_PoultryManagement.RoostersWithSymbol
    },
    nextItemStart () {
      if (!this.feeds.length) {
        return 1
      }
      return this.feeds.slice(-1)[0].end + 1 || 1
    }
  },
  methods: {
    addFeed () {
      let defaultFeed = this.allFeeds[0]
      this.feeds.push({
        start: this.nextItemStart,
        end: this.currentFlockDaysOfLife,
        gender: this.gender,
        productNumber: defaultFeed.productNumber,
        name: defaultFeed.name,
        isEditMode: true
      })
      this.$emit('updateFeed')
    },
    beginEdit (row) {
      row.isEditMode = true
    },
    endEdit (row) {
      row.isEditMode = false
    },
    remove (value) {
      const idx = this.feeds.findIndex(ele => ele === value)
      if (idx < 0) return
      this.feeds.splice(idx, 1)
      this.$emit('updateFeed')
    },
    update (index, property, value) {
      if (isNaN(value) && property !== 'feed') return
      const row = this.feeds[index]
      if (property === 'feed') {
        this.$set(row, 'productNumber', value.productNumber)
        this.$set(row, 'name', value.name)
      } else {
        this.$set(row, property, value)
        this.$set(this.feeds, index, row)
      }
      this.$emit('updateFeed')
    }
  },
  watch: {
    feeds (value) {
      this.internalFeeds = []
      if (!value.length) return
      let previousItem = null
      this.internalFeeds = value
      this.internalFeeds.forEach(feed => {
        if (!feed.hasOwnProperty('isEditMode')) {
          this.$set(feed, 'isEditMode', false)
        }
        if (previousItem) {
          this.$set(previousItem, 'end', feed.start - 1)
        }
        previousItem = feed
      })
      let lastItem = this.internalFeeds[this.internalFeeds.length - 1]
      if (!lastItem || !lastItem.end) {
        this.$set(lastItem, 'end', this.currentFlockDaysOfLife)
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.feed-recommendation {
    margin-top: 10px;
    margin-bottom: 10px;
}
.time-range-input {
  width: 50px;
  text-align: center;
}
</style>
