<template>
  <div class="poultry-houses-card-container" @click="onPoultryHouseCardClick">
    <ListCard :show-details="showDetails" class="poultry-house-card">
      <template #title>
        <IxRes>PoultryHouse</IxRes>: {{ poultryHouse.poultryHouseNumber }}
        <IxRes>Flocks</IxRes>: {{ flocksInformation }}
      </template>
      <template #header>
        <span class="female-animals-container">♀ {{ poultryHouse.numHens }}</span>
        <span class="male-animals-container">♂ {{ poultryHouse.numRoosters }}</span>
      </template>
      <div class="card-default-slot">
        <div class="main-content">
          <div class="upper-row">
            <div>
              <IxButton large @click="openVisit(1)">
                <IxRes>FirstVisit</IxRes>
              </IxButton>
            </div>
            <div>
              <IxButton large :disabled="recordSecondRunInFirst" @click="openVisit(2)">
                <IxRes>SecondVisit</IxRes>
              </IxButton>
            </div>
            <div class="simple-switch-container" @click.stop.prevent="$emit('click')">
              <SimpleSwitch :value="recordSecondRunInFirst" class="record-in-first-visit-switch" @input="onRecordSecondRunInFirst">
                <template #offLabel>
                  <IxRes>RecordInFirstVisit</IxRes>
                </template>
              </SimpleSwitch>
            </div>
            <div class="empty-container" />
          </div>
          <div class="lower-row">
            <IxButton large @click="visitPage('EggProduction')">
              <IxRes>EggProduction</IxRes>
            </IxButton>
            <IxButton large @click="visitPage('FeedAmount')">
              <IxRes>FeedAmount</IxRes>
            </IxButton>
            <IxButton large @click="visitPage('AnimalWeight')">
              <IxRes>AnimalWeight</IxRes>
            </IxButton>
            <div class="empty-container" />
          </div>
        </div>
      </div>
    </ListCard>
    <div class="status-bar" :class="poultryHouse.status || 'unprocessed'" />
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

import {mapGetters, mapMutations, mapActions} from 'vuex'

import {mapResources} from '@helpers/vuex'

import IxButton from '@components/IxButton'
import ListCard from '@components/card/ListCard'
import SimpleSwitch from '@components/forms/SimpleSwitch'

export default {
  translationNamespace: 'Areas.PoultryManagement.SR_PoultryManagement',

  components: {
    IxButton,
    ListCard,
    SimpleSwitch
  },
  props: {
    poultryHouse: Object
  },
  data () {
    return {
      recordSecondRunInFirst: false
    }
  },
  computed: {
    ...mapGetters('poultryManagement/farmer', [
      'currentFarm',
      'selectedDate',
      'expandedPoultryHouse'
    ]),
    ...mapResources([
      'Areas.PoultryManagement.SR_PoultryManagement'
    ]),
    flocksInformation () {
      if (this.poultryHouse.flocks.length > 2) {
        return this.poultryHouse.flocks.length + ' ' + this.SR_PoultryManagement.Flocks
      } else {
        return this.poultryHouse.flocks.length > 1 ? this.poultryHouse.flocks[0] + ', ' + this.poultryHouse.flocks[1] : this.poultryHouse.flocks[0]
      }
    },
    showDetails () {
      return this.expandedPoultryHouse === this.poultryHouse.poultryHouseNumber
    }
  },
  methods: {
    ...mapActions('poultryManagement/farmer', [
      'openPoultryHouseVisit'
    ]),
    ...mapActions('poultryManagement/farmer/animalWeight', [
      'loadAnimalWeight'
    ]),
    ...mapActions('poultryManagement/farmer/feedAmount', [
      'loadFeedAmount'
    ]),
    ...mapActions('poultryManagement/farmer/eggProduction', [
      'loadEggProduction'
    ]),
    ...mapMutations('poultryManagement/farmer', [
      'goToPageFullscreen',
      'setCurrentPoultryHouse',
      'setExpandedPoultryHouse',
      'setPoultryHouseStatus'
    ]),
    onPoultryHouseCardClick () {
      if (this.expandedPoultryHouse === this.poultryHouse.poultryHouseNumber) {
        this.setExpandedPoultryHouse(null)
      } else {
        this.setExpandedPoultryHouse(this.poultryHouse.poultryHouseNumber)
      }
    },
    visitPage (page) {
      this.setCurrentPoultryHouse(this.poultryHouse)
      this.loadStateForPage(page)
      this.goToPageFullscreen(page)
    },
    loadStateForPage (page) {
      switch (page) {
      case 'AnimalWeight' : {
        this.loadAnimalWeight()
        break
      }
      case 'FeedAmount' : {
        this.loadFeedAmount()
        break
      }
      case 'EggProduction' : {
        this.loadEggProduction()
        break
      }
      default: throw new Error('no action for loading specified')
      }
    },
    openVisit (visitNumber) {
      this.setCurrentPoultryHouse(this.poultryHouse)
      this.openPoultryHouseVisit(visitNumber)
    },
    getPoultryHouseStatus () {
      let poultryHouseNumber = this.poultryHouse.poultryHouseNumber
      axios.get('/api/poultrymanagement/farmer/poultryHouseStatus', {
        params: {
          farmId: this.currentFarm.id,
          date: moment(this.selectedDate).format('L'),
          poultryHouseNumber: poultryHouseNumber,
          flockNumber: this.poultryHouse.flocks[0]
        }
      })
      .then((result) => {
        this.setPoultryHouseStatus({poultryHouseNumber: this.poultryHouse.poultryHouseNumber, status: result.data.status})
        this.recordSecondRunInFirst = result.data.recordedInFirstRun
      })
      .catch(() => {
        this.setPoultryHouseStatus({poultryHouseNumber: this.poultryHouse.poultryHouseNumber, status: 'processed'})
      })
    },
    onRecordSecondRunInFirst (value) {
      let dto = {
        farmId: this.currentFarm.id,
        poultryHouse: this.poultryHouse.poultryHouseNumber,
        date: this.selectedDate,
        recordedInFirstRun: value
      }
      axios.post('/api/poultrymanagement/farmer/recordedInFirstRun', dto)
      this.recordSecondRunInFirst = value
    }
  },
  watch: {
    selectedDate (value) {
      if (value) this.getPoultryHouseStatus()
    }
  },
  mounted () {
    this.getPoultryHouseStatus()
  }
}
</script>

<style lang="scss" scoped>
.poultry-houses-card-container {
  display: grid;
  grid-template-columns: auto 10px;
  height: fit-content;
}

.poultry-house-card {
  cursor: pointer;
}

.female-animals-container {
  margin-right: 20px;
}

.main-content {
  margin-top: 10px;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 10px;
}

.upper-row {
  display: grid;
  grid-template-columns: 100px 100px auto;
  grid-gap: 10px;
}

.lower-row {
  display: grid;
  grid-template-columns: 100px 100px 100px;
  grid-gap: 10px;
}

.simple-switch-container {
  align-self: center;
}

.status-bar {
  height: 95%;

  &.unprocessed {
    background-color: red;
  }
  &.edited {
    background-color: orange
  }
  &.completed {
    background-color: green;
  }
}
</style>
