<template>
  <div class="egg-production">
    <div class="egg-type-selection">
      <div class="egg-overview-container">
        <div class="indicator" :class="statusBreedableEggs" />
        <label for="totalBreedableEggs">
          <IxRes>totalBreedableEggs</IxRes>
        </label>
        <input
          name="totalBreedableEggs"
          type="number"
          readonly="readonly"
          :value="totalBreedableEggs"
        >
        <button class="edit" @click="toggleBreedableForm">
          <IxRes v-if="breedableFormIsShown">close</IxRes>
          <IxRes v-else>edit</IxRes>
        </button>
      </div>

      <div class="egg-overview-container">
        <div class="indicator" :class="statusNoneBreedableEggs" />
        <label for="totalNoneBreedableEggs">
          <IxRes>totalNoneBreedableEggs</IxRes>
        </label>
        <input
          name="totalNoneBreedableEggs"
          type="number"
          readonly="readonly"
          :value="totalNoneBreedableEggs"
        >
        <button class="edit" @click="toggleNoneBreedableForm">
          <IxRes v-if="noneBreedableFormIsShown">close</IxRes>
          <IxRes v-else>edit</IxRes>
        </button>
      </div>
    </div>
    <BreedableEggsForm v-if="breedableFormIsShown" />
    <NoneBreedableEggsForm v-if="noneBreedableFormIsShown" />
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import IxRes from '@components/IxRes'

import BreedableEggsForm from './BreedableEggsForm'
import NoneBreedableEggsForm from './NoneBreedableEggsForm'

import eggForms from '../../../../js/store/modules/poultry-management/farmer/egg-production/egg-forms'

export default {
  translationNamespace: 'Areas.PoultryManagement.SR_PoultryManagement',

  components: {
    IxRes,
    BreedableEggsForm,
    NoneBreedableEggsForm
  },

  computed: {
    ...mapGetters('poultryManagement/farmer', [
      'currentVisit'
    ]),

    ...mapGetters('poultryManagement/farmer/eggProduction', [
      'currentForm',
      'packageTypes',
      'totalBreedableEggs',
      'totalNoneBreedableEggs',
      'statusBreedableEggs',
      'statusNoneBreedableEggs'
    ]),

    breedableFormIsShown () {
      return this.currentForm === eggForms.breedableEggs
    },

    noneBreedableFormIsShown () {
      return this.currentForm === eggForms.nonBreedableEggs
    }
  },

  methods: {
    ...mapMutations('poultryManagement/farmer/eggProduction', [
      'showForm',
      'setBreedableEggsEntered',
      'setNoneBreedableEggsEntered'
    ]),

    toggleBreedableForm () {
      if (this.breedableFormIsShown) {
        this.setBreedableEggsEntered()
        this.showForm(eggForms.none)
      } else {
        this.showForm(eggForms.breedableEggs)
      }
    },

    toggleNoneBreedableForm () {
      if (this.noneBreedableFormIsShown) {
        this.setNoneBreedableEggsEntered()
        this.showForm(eggForms.none)
      } else {
        this.showForm(eggForms.nonBreedableEggs)
      }
    }

  }
}
</script>

<style lang="scss" scoped>

  .egg-production {
    display: grid;
    grid: auto / 2fr 3fr;

    .egg-type-selection {
      justify-self: end;
      border-right: 1px solid dimgrey;
      padding-right: 10px;
    }

  .egg-overview-container {
    display: grid;
    grid: auto auto / min-content auto 1fr;
    grid-column-gap: 5px;

    margin-bottom: 10px;

    .indicator {
      grid-row: span 2;
      width: 5px;

      &.complete {
        background-color: green;
      }

      &.incomplete {
        background-color: orange;
      }
    }

      input {
        grid-column: 2;
      }
    }
  }
</style>
