<template>
  <div class="flock-management-container">
    <a @click="back">
      <IxRes>GoBackToFarmOverview</IxRes>
    </a>
    <div>
      <IxRes>Herd</IxRes>
      <strong>{{ ' ' }} {{ flockNumber }} {{ ' ' }}</strong>
      <IxRes>Breed</IxRes>:
      {{ breed }}
    </div>
    <div>
      <IxRes>type</IxRes>:
      <BsRadio v-model="internalType" value="eggproduction">
        <IxRes>eggProduction</IxRes>
      </BsRadio>
      <BsRadio v-model="internalType" value="breeding">
        <IxRes>breeding</IxRes>
      </BsRadio>
    </div>

    <div class="instablement-outstablement-container">
      <div>
        <label>
          <IxRes>instablementDate</IxRes>
        </label>
        <p>{{ placementDateText }}</p>
      </div>
      <div>
        <label>
          <IxRes>exactOutStabelingDateLabel</IxRes>
        </label>
        <p>{{ outstablementDateText }}</p>
      </div>
      <div>
        <IxButton large @click="outstableFlock">
          <i slot="icon" class="fa fa-sign-out" />
          <IxRes>outStableFlock</IxRes>
        </IxButton>
      </div>
    </div>

    <div class="poultry-houses-with-flock">
      <IxRes>PoultryHousesWithFlock</IxRes>
      <SimpleTable
        :data="rows" :columns="columns"
        localize-headers :highlight="highlight"
      >
        <template #poultryHouseNumber="{rowIndex, columnIndex, row, value}">
          <template v-if="!row.isFooter">
            {{ value }}
          </template>
          <template v-else>
            <strong>
              {{ value }}
            </strong>
          </template>
        </template>
      </SimpleTable>
    </div>

    <IxRes>SetFeedRecommendation</IxRes>
    <div class="feed-recommendation-container">
      <FeedRecommendation
        :feeds="henFeed" gender="hen"
        class="hen-feed" @updateFeed="onFeedUpdate"
      />
      <FeedRecommendation
        :feeds="roosterFeed" gender="rooster"
        class="rooster-feed" @updateFeed="onFeedUpdate"
      />
    </div>

    <div class="flock-management-footer-buttons">
      <IxButton
        large save
        :disabled="!dataChanged" @click="saveChanges"
      >
        <IxRes>SaveChanges</IxRes>
      </IxButton>
      <IxButton large :disabled="!dataChanged" @click="setValuesFromStore">
        <i slot="icon" class="fa fa-undo" />
        <IxRes>DiscardChanges</IxRes>
      </IxButton>
      <IxButton large @click="removeFlock">
        <i slot="icon" class="fa fa-trash" />
        <IxRes>RemoveFlock</IxRes>
      </IxButton>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
import {cloneDeep} from 'lodash'
import moment from 'moment'

import {mapResources} from '@helpers/vuex'

import IxButton from '@components/IxButton'
import BsRadio from '@components/bootstrap/BsRadio'
import SimpleTable from '@components/table/SimpleTable'
import FeedRecommendation from './FeedRecommendation'

export default {
  translationNamespace: 'Areas.PoultryManagement.SR_PoultryManagement',

  components: {
    BsRadio,
    IxButton,
    SimpleTable,
    FeedRecommendation
  },
  data () {
    return {
      highlight: x => x.isFooter ? ['success'] : [],
      internalType: null,
      henFeed: [],
      roosterFeed: [],
      feedUpdated: false
    }
  },
  computed: {
    ...mapGetters('poultryManagement/productionManager', {
      flockNumber: 'currentFlockNumber',
      breed: 'currentFlockBreed',
      currentFlockType: 'currentFlockType',
      placementDates: 'currentFlockPlacementDates',
      hatchingDate: 'currentFlockHatchingDate',
      depletingDate: 'currentFlockDepletingDate',
      flockRows: 'currentFlockPoultryHouseOccupancy'
    }),
    ...mapGetters('poultryManagement/productionManager', [
      'currentFlockFeedRecommendation'
    ]),
    ...mapResources([
      'Areas.PoultryManagement.SR_PoultryManagement'
    ]),
    rows () {
      return this.flockRows.concat({
        poultryHouseNumber: 'Gesamt',
        numHens: this.flockRows.reduce((acc, row) => acc + row.numHens, 0),
        numRoosters: this.flockRows.reduce((acc, row) => acc + row.numRoosters, 0),
        isFooter: true
      })
    },
    columns () {
      return {
        poultryHouseNumber: {title: 'Areas.PoultryManagement.SR_PoultryManagement.PoultryHouse', sortable: false},
        numHens: {title: 'Areas.PoultryManagement.SR_PoultryManagement.numberFemale', sortable: false},
        numRoosters: {title: 'Areas.PoultryManagement.SR_PoultryManagement.numberMale', sortable: false}
      }
    },
    dataChanged () {
      let typeChanged = this.internalType !== this.currentFlockType
      return typeChanged || this.feedUpdated
    },
    placementDateText () {
      let initialPlacementDate = moment(this.placementDates[0])
      return initialPlacementDate.format('L') + ' (' + initialPlacementDate.diff(this.hatchingDate, 'days') + '. LT)'
    },
    outstablementDateText () {
      if (!this.depletingDate) {
        return this.SR_PoultryManagement.NoEntry
      } else {
        let depletingDate = moment(this.depletingDate)
        let hatchingDate = moment(this.hatchingDate)
        return depletingDate.format('L') + ' (' + depletingDate.diff(hatchingDate, 'days') + '. LT)'
      }
    }
  },
  methods: {
    ...mapActions('poultryManagement/productionManager', [
      'deleteFlock',
      'updateFlockDetails'
    ]),
    ...mapMutations('poultryManagement/productionManager', [
      'goToPage'
    ]),
    outstableFlock () {
      this.goToPage('OutstableFlock')
    },
    back () {
      this.goToPage('Overview')
    },
    saveChanges () {
      let dto = {
        flockNumber: this.flockNumber,
        type: this.internalType,
        feedRecommendation: this.henFeed.concat(this.roosterFeed)
      }
      this.updateFlockDetails(dto)
    },
    setValuesFromStore () {
      this.internalType = this.currentFlockType
      this.henFeed = cloneDeep(this.currentFlockFeedRecommendation.filter(x => x.gender === 'hen'))
      this.roosterFeed = cloneDeep(this.currentFlockFeedRecommendation.filter(x => x.gender === 'rooster'))
      this.feedUpdated = false
    },
    removeFlock () {
      this.deleteFlock(this.flockNumber)
    },
    onFeedUpdate () {
      this.feedUpdated = true
    }
  },
  mounted () {
    this.setValuesFromStore()
  }
}
</script>

<style lang="scss">
.poultry-houses-with-flock {
  width: 60%;
}

.instablement-outstablement-container {
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;

  & > * {
    margin-right: 10px;
  }
}

.feed-recommendation-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
</style>
