var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flock-outstable-container"},[_c('h3',[_c('IxRes',[_vm._v("outStableFlock")])],1),_c('div',[_c('IxRes',[_vm._v("Number")]),_vm._v(":\n    "+_vm._s(_vm.flockNumber)+"\n  ")],1),_c('div',[_c('IxRes',[_vm._v("Breed")]),_vm._v(":\n    "+_vm._s(_vm.breed)+"\n  ")],1),_c('div',[_c('IxRes',[_vm._v("type")]),_vm._v(":\n    "+_vm._s(_vm.flockTypeInfo)+"\n  ")],1),_c('div',{staticClass:"date-container"},[_c('VueDateTimePicker',{model:{value:(_vm.depletingDate),callback:function ($$v) {_vm.depletingDate=$$v},expression:"depletingDate"}},[_c('IxRes',{attrs:{"slot":"label"},slot:"label"},[_vm._v("exactOutStabelingDateLabel")])],1)],1),_c('div',[_c('IxRes',[_vm._v("OutstableAmountText")]),_c('SimpleTable',{staticClass:"poultry-houses-with-flock",attrs:{"data":_vm.rows,"columns":_vm.columns,"localize-headers":"","highlight":_vm.highlight},scopedSlots:_vm._u([{key:"numHens",fn:function(ref){
var rowIndex = ref.rowIndex;
var columnIndex = ref.columnIndex;
var row = ref.row;
return [(!row.isFooter)?_c('div',{staticClass:"input-row"},[_c('NumericInput',{model:{value:(row.numHens),callback:function ($$v) {_vm.$set(row, "numHens", $$v)},expression:"row.numHens"}}),_c('span',[_c('IxRes',[_vm._v("Of")]),_vm._v("\n            "+_vm._s(_vm.poultryHouseOccupancy[rowIndex].numHens)+"\n          ")],1)],1):_c('span',[_vm._v(" "+_vm._s(row.numHens)+" ")])]}},{key:"numRoosters",fn:function(ref){
var rowIndex = ref.rowIndex;
var columnIndex = ref.columnIndex;
var row = ref.row;
return [(!row.isFooter)?_c('div',{staticClass:"input-row"},[(!row.isFooter)?_c('NumericInput',{model:{value:(row.numRoosters),callback:function ($$v) {_vm.$set(row, "numRoosters", $$v)},expression:"row.numRoosters"}}):_vm._e(),_c('span',[_c('IxRes',[_vm._v("Of")]),_vm._v("\n            "+_vm._s(_vm.poultryHouseOccupancy[rowIndex].numRoosters)+"\n          ")],1)],1):_c('span',[_vm._v(" "+_vm._s(row.numRoosters)+" ")])]}},{key:"poultryHouseNumber",fn:function(ref){
var rowIndex = ref.rowIndex;
var columnIndex = ref.columnIndex;
var row = ref.row;
var value = ref.value;
return [(!row.isFooter)?[_vm._v("\n          "+_vm._s(value)+"\n        ")]:[_c('strong',[_vm._v("\n            "+_vm._s(value)+"\n          ")])]]}}])})],1),_c('div',[_c('IxButton',{attrs:{"save":"","large":"","icon":"blank"},on:{"click":_vm.onSubmit}},[_c('span',{staticClass:"fa-stack",attrs:{"slot":"icon"},slot:"icon"},[_c('i',{staticClass:"fa fa-home fa-stack-1x"}),_c('i',{staticClass:"fa fa-ban fa-stack-2x text-danger"})]),_c('IxRes',[_vm._v("outStableFlock")])],1),_c('IxButton',{staticClass:"cancel-button",attrs:{"cancel":"","large":""},on:{"click":_vm.onCancel}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }