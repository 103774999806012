<template lang="html">
  <table class="frs-simple-table table table-striped table-condensed table-bordered table-hover table-responsive" :class="{'fixed-header': fixedHeader}">
    <thead>
      <tr :style="{width: headerWidth + 'px'}">
        <th v-for="(column, i) in headerData" :key="i" :style="{width: column.width}">
          <slot :name="`header-${column.id}`" :title="column.title">
            <IxRes v-if="(localizeHeaders || column.localize) && column.title">{{ column.title }}</IxRes>
            <template v-else>
              {{ column.title }}
            </template>
          </slot>
          <SimpleTableSortingButton
            v-if="column.sortable" :active="i === sortedBy"
            :descending="descending"
            :type="column.type" @click="sortBy(i)"
          />
        </th>
      </tr>
    </thead>
    <tbody ref="tableBody" @scroll="onScroll">
      <template v-if="data.length">
        <slot v-for="(row, i) in sortedData" :row="row" :index="i">
          <tr :key="i" :class="highlight ? highlight(row, i) : []">
            <td
              v-for="(column, j) in headerData" :key="j"
              :style="{width: column.width}"
              @click="$emit('click', {
                row,
                column,
                rowIndex: i,
                columnIndex: j,
                value: column.value(row, i),
                event: $event
              })"
            >
              <slot
                :name="`${column.id}`"
                :row="row"
                :column="column"
                :row-index="i"
                :column-index="j"
                :value="column.value(row, i)"
              >
                <SimpleTableAutoCell :value="column.value(row, i)" :type="column.type" :format-string="column.formatString" />
              </slot>
            </td>
          </tr>
        </slot>
      </template>
      <tr v-else>
        <td class="no-data" :colspan="headerData.length">
          <IxRes>Common.SR_Common.SimpleTable.NoData</IxRes>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import moment from 'moment'

import SimpleTableSortingButton from './SimpleTableSortingButton'
import SimpleTableAutoCell from './SimpleTableAutoCell'

const defaultSortingFunctions = {
  string: (a, b) => a.localeCompare(b),
  number: (a, b) => a - b,
  date: (a, b) => moment(a) - moment(b),
  datetime: (a, b) => moment(a) - moment(b),
  undefined: (a, b) => a.toString().localeCompare(b.toString())
}

const defaultSortingIcons = {
  string: 'sort-alpha',
  number: 'sort-numeric',
  date: 'sort-numeric',
  datetime: 'sort-numeric',
  undefined: 'sort'
}

export default {
  components: {
    SimpleTableSortingButton,
    SimpleTableAutoCell
  },
  props: {
    columns: Object,
    localizeHeaders: Boolean,
    fixedHeader: Boolean,
    data: Array,
    highlight: Function, // returns one of the standard bootstrap color classes (info, warning, error, success, active)
    disableInternalSort: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      sortedBy: null,
      descending: true,
      headerWidth: null
    }
  },
  computed: {
    headerData () {
      return Object.keys(this.columns)
        .map(key => {
          const settings = this.columns[key]
          const {type, sortable, value = x => x[key], localize} = settings
          const {sortingFunction = defaultSortingFunctions[type], sortingIcon = defaultSortingIcons[type]} = settings

          return {
            id: key,
            title: typeof settings === 'string'
              ? settings
              : settings.title !== undefined
                ? settings.title
                : key,
            localize,
            type,
            value,
            sortable,
            sortingFunction,
            sortingIcon,
            width: settings.width,
            formatString: settings.formatString
          }
        })
    },

    sortedData () {
      if (this.sortedBy === null || this.disableInternalSort) return this.data

      const data = [...this.data]
      const {value, sortingFunction} = this.headerData[this.sortedBy]

      const f = this.descending
        ? (a, b) => sortingFunction(value(b, this.data.indexOf(b)), value(a, this.data.indexOf(a)))
        : (a, b) => sortingFunction(value(a, this.data.indexOf(a)), value(b, this.data.indexOf(b)))

      data.sort(f)
      return data
    }
  },
  methods: {
    sortBy (i) {
      if (this.sortedBy === i && this.descending) {
        this.sortedBy = null
        this.descending = false
      } else if (this.sortedBy === i) {
        this.descending = true
      } else {
        this.sortedBy = i
        this.descending = false
      }

      const sortedEventArgs = this.sortedBy === null
        ? null
        : {column: this.headerData[i], descending: this.descending}

      this.$emit('sorted', sortedEventArgs)
    },

    onScroll (event) {
      this.$emit('scroll', event)
    }
  },

  updated () {
    this.headerWidth = this.$refs.tableBody.clientWidth
  }
}
</script>

<style lang="scss" scoped>
.frs-simple-table thead th {
  background-color: #ddd;
  padding-top: 0;
  padding-bottom: 0;
}

.frs-simple-table tbody tr {
  user-select: none;
}

.no-data {
  text-align: center;
}

.frs-simple-table.fixed-header {

  height: 100%;

  thead {
    background-color: #ddd;
    tr {
        display: block;

        th {
          display: inline-block;
        }
      }
  }

  tbody {
    display: block;
    overflow-y: scroll;
    height: 100%;
  }
}

</style>
