var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"poultry-house-management"},[_c('div',{staticClass:"back-link"},[_c('i',{staticClass:"fa fa-arrow-circle-o-left"}),_c('a',{on:{"click":_vm.goToFarm}},[_c('IxRes',[_vm._v("GoBackToFarmOverview")])],1)]),_c('div',{staticClass:"poultry-house-management-content"},[_c('div',{staticClass:"left-column"},[_c('div',[_c('div',{staticClass:"occupation-container"},[_c('div',{staticClass:"occupation-container-stats"},[_c('IxRes',[_vm._v("PoultryHouse")]),_vm._v(":\n            "),_c('span',[_vm._v(_vm._s(_vm.getStableNumber))]),_c('i',{staticClass:"fa fa-venus"}),_c('span',[_vm._v(_vm._s(_vm.getNumberOfFemales))]),_c('i',{staticClass:"fa fa-mars"}),_c('span',[_vm._v(_vm._s(_vm.getNumberOfMales))])],1),(_vm.getFlocks.length)?_c('div',[_c('IxRes',[_vm._v("flocks")]),_vm._v(":\n            "),_c('ul',_vm._l((_vm.getFlocks),function(flock,idx){return _c('li',{key:idx},[_vm._v("\n                "+_vm._s(flock)+"\n              ")])}),0)],1):_vm._e()]),_c('div',[_c('h4',[_c('IxRes',[_vm._v("SetGenderSpecificAmountOfFeed")])],1),_c('div',{staticClass:"pm-flex-row"},[_c('DatePicker',{model:{value:(_vm.genderSpecificFoodDate),callback:function ($$v) {_vm.genderSpecificFoodDate=$$v},expression:"genderSpecificFoodDate"}}),_c('IxRes',[_vm._v("fromThe")]),_c('NumberInput',{model:{value:(_vm.dayOfLiving),callback:function ($$v) {_vm.dayOfLiving=$$v},expression:"dayOfLiving"}}),_c('IxRes',[_vm._v("DayOfLife")])],1),_c('SimpleTable',{attrs:{"data":_vm.sortedFeed,"columns":_vm.getFeedColumns,"localize-headers":""},scopedSlots:_vm._u([{key:"timeRange",fn:function(ref){
var rowIndex = ref.rowIndex;
var value = ref.value;
var row = ref.row;
return [(_vm.isFeedRowInEditMode(rowIndex))?[_c('DatePicker',{attrs:{"value":value},on:{"input":function($event){return _vm.updateTimeRange({item: row, timeRange: $event})}}})]:[_vm._v("\n                "+_vm._s(_vm._f("formatDate")(value))+"\n              ")]]}},{key:"amountFemale",fn:function(ref){
var rowIndex = ref.rowIndex;
var value = ref.value;
var row = ref.row;
return [(_vm.isFeedRowInEditMode(rowIndex))?[_c('NumberInput',{attrs:{"value":value},on:{"input":function($event){return _vm.updateAmountFemale({item: row, newAmount: $event})}}})]:[_vm._v("\n                "+_vm._s(value)+"\n              ")]]}},{key:"amountMale",fn:function(ref){
var rowIndex = ref.rowIndex;
var value = ref.value;
var row = ref.row;
return [(_vm.isFeedRowInEditMode(rowIndex))?[_c('NumberInput',{attrs:{"value":value},on:{"input":function($event){return _vm.updateAmountMale({item: row, newAmount: $event})}}})]:[_vm._v("\n                "+_vm._s(value)+"\n              ")]]}},{key:"actions",fn:function(ref){
var rowIndex = ref.rowIndex;
var row = ref.row;
return [_c('a',{on:{"click":function($event){return _vm.switchFeedView(rowIndex)}}},[(_vm.isFeedRowInEditMode(rowIndex))?_c('i',{staticClass:"fa fa-check"}):_c('i',{staticClass:"fa fa-pencil"})]),_c('a',{on:{"click":function($event){return _vm.deleteFeed(row)}}},[_c('i',{staticClass:"fa fa-trash"})])]}}])}),_c('a',{on:{"click":_vm.addFeed}},[_c('IxRes',[_vm._v("addFeed")])],1)],1),_c('div',[_c('h4',[_c('IxRes',[_vm._v("dailyRecords")])],1),_vm._v("\n          "+_vm._s(_vm.tillYesterdayMessage)+"\n          "),(_vm.getDailyMessages.length !== 0)?_c('ol',_vm._l((_vm.getDailyMessages),function(dailyMessage){return _c('li',{key:dailyMessage.id},[_vm._v("\n              "+_vm._s(dailyMessage.date)+"\n            ")])}),0):_vm._e()]),_c('div',[_c('h4',[_c('IxRes',[_vm._v("useofAntiboticas")])],1),(_vm.antiboticasAreUsed)?_c('div',[_c('i',{staticClass:"fa fa-check-square-o"}),_c('IxRes',[_vm._v("antiboticasAreUsedInThisPoultryHouse")])],1):_c('div',[_c('i',{staticClass:"fa fa-square-o"}),_c('IxRes',[_vm._v("antiboticasAreNotUsedInThisPoultryHouse")])],1),_c('SimpleTable',{attrs:{"data":_vm.getAntibioticas,"columns":_vm.getAntiboticasColumns,"localize-headers":""},scopedSlots:_vm._u([{key:"from",fn:function(ref){
var rowIndex = ref.rowIndex;
var value = ref.value;
var row = ref.row;
return [(_vm.isAntibioticaRowInEditMode(rowIndex))?[_c('DatePicker',{attrs:{"value":value},on:{"input":function($event){return _vm.updateFromDate({item: row, from: $event})}}})]:[_vm._v("\n                "+_vm._s(value.format('L'))+"\n              ")]]}},{key:"to",fn:function(ref){
var rowIndex = ref.rowIndex;
var value = ref.value;
var row = ref.row;
return [(_vm.isAntibioticaRowInEditMode(rowIndex))?[_c('DatePicker',{attrs:{"value":value},on:{"input":function($event){return _vm.updateToDate({item: row, to: $event})}}})]:[_vm._v("\n                "+_vm._s(value.format('L'))+"\n              ")]]}},{key:"actions",fn:function(ref){
var row = ref.row;
var rowIndex = ref.rowIndex;
return [_c('a',{on:{"click":function($event){return _vm.switchAntibioticaView(rowIndex)}}},[(_vm.isAntibioticaRowInEditMode(rowIndex))?_c('i',{staticClass:"fa fa-check"}):_c('i',{staticClass:"fa fa-pencil"})]),_c('a',{on:{"click":function($event){return _vm.deleteAntiboticaUse(row)}}},[_c('i',{staticClass:"fa fa-trash"})])]}}])}),_c('a',{on:{"click":_vm.addUseOfAntibiotica}},[_c('IxRes',[_vm._v("addTimePeriod")])],1)],1)])]),_c('div',{staticClass:"right-column"},[_c('fieldset',[_c('legend',[_c('IxRes',[_vm._v("contact")])],1),_c('TextInput',{model:{value:(_vm.contactName),callback:function ($$v) {_vm.contactName=$$v},expression:"contactName"}},[_c('template',{slot:"label"},[_c('IxRes',[_vm._v("name")])],1)],2),_c('TextInput',{model:{value:(_vm.contactPhone),callback:function ($$v) {_vm.contactPhone=$$v},expression:"contactPhone"}},[_c('template',{slot:"label"},[_c('IxRes',[_vm._v("phone")])],1)],2)],1)])]),_c('div',{staticClass:"button-container"},[_c('IxButton',{attrs:{"save":"","large":""},on:{"click":_vm.submitPoultryHouse}}),_c('IxButton',{attrs:{"cancel":"","large":""},on:{"click":_vm.reset}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }