<template>
  <div class="flock-overview-container">
    <h3>
      <span>{{ totalFlocks }} {{ ' ' }}</span>
      <IxRes>flocks</IxRes>
    </h3>
    <SimpleTable :columns="columns" :data="currentFlocks" localize-headers>
      <template slot="manageFlocks" slot-scope="{row}">
        <a @click="goToFlock(row.flockNumber)">
          <IxRes>manage</IxRes>
        </a>
      </template>
    </SimpleTable>
    <IxButton large icon="plus" @click="instableNewFlock">
      <IxRes>instableFlock</IxRes>
    </IxButton>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

import IxButton from '@components/IxButton'
import SimpleTable from '@components/table/SimpleTable'

export default {
  translationNamespace: 'Areas.PoultryManagement.SR_PoultryManagement',

  components: {
    IxButton,
    SimpleTable
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters('poultryManagement/productionManager', [
      'currentFlocks',
      'totalFlocks'
    ]),
    columns () {
      return {
        flockNumber: {title: 'Areas.PoultryManagement.SR_PoultryManagement.Number', sortable: true},
        arrivalDate: {title: 'Areas.PoultryManagement.SR_PoultryManagement.instablementDate', sortable: false, type: 'date'},
        poultryHouses: {title: 'Areas.PoultryManagement.SR_PoultryManagement.poultryHouses', sortable: false},
        manageFlocks: {title: '', sortable: false}
      }
    }
  },
  methods: {
    ...mapActions('poultryManagement/productionManager', [
      'goToFlock',
      'instableNewFlock'
    ])
  }
}

</script>

<style lang="scss">
  a {
    cursor: pointer;
  }
</style>
