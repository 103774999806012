<template>
  <div class="capture-none-breedable-eggs">
    <form class="capture-form">
      <div>
        <label for="inputNoneBreedableEggs" :disabled="useDetailedData">
          <IxRes>NoneBreedableEggs</IxRes>:
        </label>
        <input
          id="inputNoneBreedableEggs"
          v-model.number="internalNoneBreedableEggs"
          type="number"
          :disabled="useDetailedData"
        >
      </div>
      <div class="detailed-data-container">
        <simple-switch v-model="useDetailedData">
          <IxRes>useDetailedData</IxRes>
        </simple-switch>
      </div>
      <div>
        <label for="smallEggs" :disabled="!useDetailedData">
          <IxRes>smallEggs</IxRes>
        </label>
        <input
          id="smallEggs"
          v-model.number="smallEggs"
          type="number"
          :disabled="!useDetailedData"
        >
      </div>
      <div>
        <label for="doubleYolk" :disabled="!useDetailedData">
          <IxRes>doubleYolk</IxRes>
        </label>
        <input
          id="doubleYolk"
          v-model.number="doubleYolk"
          type="number"
          :disabled="!useDetailedData"
        >
      </div>
      <div>
        <label for="dirtyEggs" :disabled="!useDetailedData">
          <IxRes>dirtyEggs</IxRes>
        </label>
        <input
          id="dirtyEggs"
          v-model.number="dirtyEggs"
          type="number"
          :disabled="!useDetailedData"
        >
      </div>

      <div>
        <label for="thrownAway" :disabled="!useDetailedData">
          <IxRes>thrownAway</IxRes>
        </label>
        <input
          id="thrownAway"
          v-model.number="thrownAway"
          type="number"
          :disabled="!useDetailedData"
        >
      </div>
    </form>
  </div>
</template>

<script>
import IxRes from '@components/IxRes'
import SimpleSwitch from '@components/forms/SimpleSwitch'
import {mapGetters, mapMutations} from 'vuex'
import {mapFormFields} from '@helpers/vuex'

export default {

  translationNamespace: 'Areas.PoultryManagement.SR_PoultryManagement',

  components: {
    SimpleSwitch,
    IxRes
  },

  computed: {
    ...mapGetters('poultryManagement/farmer/eggProduction', [
      'noneBreedableEggs'
    ]),

    ...mapFormFields('poultryManagement/farmer/eggProduction', [
      'data.useDetailedData',
      'data.smallEggs',
      'data.doubleYolk',
      'data.dirtyEggs',
      'data.thrownAway'
    ]),

    internalNoneBreedableEggs: {
      get () { return this.noneBreedableEggs },
      set (value) { this.setNoneBreedableEggs(value) }
    }
  },

  methods: {
    ...mapMutations('poultryManagement/farmer/eggProduction', [
      'setNoneBreedableEggs'
    ])
  }
}

</script>

<style lang="scss" scoped>

  .capture-none-breedable-eggs {
    padding-left: 5px;

    .capture-form{
      display: grid;
      grid: auto auto / min-content min-content min-content min-content;
      grid-column-gap: 5px;

      div > label {
        display: block;
      }
    }

    .detailed-data-container{
      grid-column-start: span 3;
    }
  }

</style>
