<template>
  <div class="flock-outstable-container">
    <h3>
      <IxRes>outStableFlock</IxRes>
    </h3>
    <div>
      <IxRes>Number</IxRes>:
      {{ flockNumber }}
    </div>
    <div>
      <IxRes>Breed</IxRes>:
      {{ breed }}
    </div>
    <div>
      <IxRes>type</IxRes>:
      {{ flockTypeInfo }}
    </div>
    <div class="date-container">
      <VueDateTimePicker v-model="depletingDate">
        <IxRes slot="label">exactOutStabelingDateLabel</IxRes>
      </VueDateTimePicker>
    </div>
    <div>
      <IxRes>OutstableAmountText</IxRes>
      <SimpleTable
        :data="rows" :columns="columns"
        localize-headers :highlight="highlight"
        class="poultry-houses-with-flock"
      >
        <template #numHens="{rowIndex, columnIndex, row}">
          <div v-if="!row.isFooter" class="input-row">
            <NumericInput v-model="row.numHens" />
            <span>
              <IxRes>Of</IxRes>
              {{ poultryHouseOccupancy[rowIndex].numHens }}
            </span>
          </div>
          <span v-else> {{ row.numHens }} </span>
        </template>
        <template #numRoosters="{rowIndex, columnIndex, row}">
          <div v-if="!row.isFooter" class="input-row">
            <NumericInput v-if="!row.isFooter" v-model="row.numRoosters" />
            <span>
              <IxRes>Of</IxRes>
              {{ poultryHouseOccupancy[rowIndex].numRoosters }}
            </span>
          </div>
          <span v-else> {{ row.numRoosters }} </span>
        </template>
        <template #poultryHouseNumber="{rowIndex, columnIndex, row, value}">
          <template v-if="!row.isFooter">
            {{ value }}
          </template>
          <template v-else>
            <strong>
              {{ value }}
            </strong>
          </template>
        </template>
      </SimpleTable>
    </div>
    <div>
      <IxButton
        save
        large
        icon="blank"
        @click="onSubmit"
      >
        <span slot="icon" class="fa-stack">
          <i class="fa fa-home fa-stack-1x" />
          <i class="fa fa-ban fa-stack-2x text-danger" />
        </span>
        <IxRes>outStableFlock</IxRes>
      </IxButton>
      <IxButton
        class="cancel-button" cancel
        large @click="onCancel"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {cloneDeep, isEqual} from 'lodash'
import {mapGetters, mapActions, mapMutations} from 'vuex'
import {mapResources} from '@helpers/vuex'

import IxButton from '@components/IxButton'
import SimpleTable from '@components/table/SimpleTable'
import VueDateTimePicker from '@components/forms/VueDateTimePicker'
import NumericInput from '@components/forms/NumericInput'

export default {
  translationNamespace: 'Areas.PoultryManagement.SR_PoultryManagement',

  components: {
    IxButton,
    SimpleTable,
    NumericInput,
    VueDateTimePicker
  },

  data () {
    return {
      depletingDate: moment(),
      highlight: x => x.isFooter ? ['success'] : [],
      flockRows: []
    }
  },

  computed: {
    ...mapResources(['@pm.SR_PoultryManagement']),
    ...mapGetters('poultryManagement/productionManager', {
      currentFarm: 'currentFarm',
      flockNumber: 'currentFlockNumber',
      breed: 'currentFlockBreed',
      type: 'currentFlockType',
      poultryHouseOccupancy: 'currentFlockPoultryHouseOccupancy'
    }),
    rows () {
      return this.flockRows.concat({
        poultryHouseNumber: 'Gesamt',
        numHens: this.flockRows.reduce((acc, row) => acc + row.numHens, 0),
        numRoosters: this.flockRows.reduce((acc, row) => acc + row.numRoosters, 0),
        isFooter: true
      })
    },
    columns () {
      return {
        poultryHouseNumber: {title: 'Areas.PoultryManagement.SR_PoultryManagement.PoultryHouse', sortable: false},
        numHens: {title: 'Areas.PoultryManagement.SR_PoultryManagement.numberFemale', sortable: false},
        numRoosters: {title: 'Areas.PoultryManagement.SR_PoultryManagement.numberMale', sortable: false}
      }
    },
    flockTypeInfo () {
      return this.type === 'eggproduction' ? this.SR_PoultryManagement.eggProduction : this.SR_PoultryManagement.breeding
    },
    depleteAllAnimals () {
      return isEqual(this.flockRows, this.poultryHouseOccupancy)
    }
  },

  methods: {
    ...mapActions('poultryManagement/productionManager', [
      'depleteAnimals',
      'depleteWholeFlock'
    ]),
    ...mapMutations('poultryManagement/productionManager', [
      'goToPage'
    ]),
    onSubmit () {
      if (this.depleteAllAnimals) {
        let dto = {
          flockNumber: this.flockNumber,
          date: this.depletingDate.format('YYYY-MM-DD')
        }
        this.depleteWholeFlock(dto)
      } else {
        const dto = {
          flockNumber: this.flockNumber,
          farmId: this.currentFarm.id,
          depletingDate: this.depletingDate.format('YYYY-MM-DD'),
          assignedPoultryHouses: this.flockRows.map(item => ({
            poultryHouseId: item.poultryHouseId,
            numHens: item.numHens,
            numRoosters: item.numRoosters,
            poultryHouseNumber: item.poultryHouseNumber
          }))
        }
        this.depleteAnimals(dto)
      }
    },
    onCancel () {
      this.goToPage('flockManagement')
    }
  },
  mounted () {
    this.flockRows = cloneDeep(this.poultryHouseOccupancy)
  }
}

</script>

<style lang="scss">
  .flock-outstable-container {
    display: flex;
    flex-direction: column;

    .date-container {
      width: fit-content;
    }

    &>*{
      margin-bottom: 10px;
    }

    .fa-stack {
      height: 1em;
    }

    .fa-home {
      line-height: 0.8;
    }

    .fa.fa-ban.fa-stack-2x.text-danger {
      font-size: 2em;
      line-height: 0.5;
    }
  }
  .poultry-houses-with-flock {
    width: 60%;
  }

  .cancel-button {
    margin-left: 5px;
  }

  .input-row {
    display: grid;
    grid-template-columns: 100px auto;
    grid-gap: 5px;
  }
</style>
