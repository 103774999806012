<template>
  <div class="capture-breedable-eggs">
    <h3><IxRes>breedingEggs</IxRes></h3>
    <div class="capture-form">
      <div class="capture-row">
        <span><IxRes>PackageType</IxRes></span>
        <span><IxRes>NumberOfPackages</IxRes></span>
      </div>
      <div v-for="(eggPackage, index) in eggPackages" :key="eggPackage.id" class="capture-row">
        <select :value="eggPackage.packageType" @input="updateEggPackageType({eggPackageId: index, type: Number.parseInt($event.target.value)})">
          <option
            v-for="packageType in packageTypes"
            :key="packageType.id"
            :value="packageType.id"
          >
            {{ packageType.name }} ({{ packageType.capacity }} Eier)
          </option>
        </select>
        <input
          :value="eggPackage.amount"
          type="number"
          @input="updateEggPackageAmount({eggPackageId: index, amount: Number.parseInt($event.target.value)})"
        >
      </div>
    </div>
    <button @click="addEggPackage">
      <IxRes>add</IxRes>
    </button>
    <div class="capture-row with-gutter">
      <label><IxRes>remainingPackagedEggs</IxRes></label>
      <input v-model.number="$_remainingPackgedEggs" type="number">
    </div>
    <hr>
    <IxRes>TotalBreedableEggs</IxRes>:
    <b>
      {{ totalBreedableEggs.toLocaleString() }}
    </b>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import IxRes from '@components/IxRes'

export default {
  translationNamespace: 'Areas.PoultryManagement.SR_PoultryManagement',

  components: {
    IxRes
  },

  computed: {
    ...mapGetters('poultryManagement/farmer', [
      'currentVisit'
    ]),
    ...mapGetters('poultryManagement/farmer/eggProduction', [
      'packageTypes',
      'totalBreedableEggs',
      'eggPackages',
      'remainingPackagedEggs'
    ]),

    $_remainingPackgedEggs: {
      get () { return this.remainingPackagedEggs },
      set (value) { this.setRemainingPackagedEggs(value) }
    }
  },

  methods: {
    ...mapMutations('poultryManagement/farmer/eggProduction', [
      'addEggPackage',
      'setRemainingPackagedEggs',
      'updateEggPackageAmount',
      'updateEggPackageType'
    ])
  }
}
</script>

<style lang="scss" scoped>

  .capture-breedable-eggs {
    padding-left: 10px;
    width: 500px;
  }

  .with-gutter {
    margin-top: 20px;
  }

  .capture-row {
    display: grid;
    grid: auto / 1fr 1fr;
    grid-gap: 5px 5px;
    margin-bottom: 5px;

    label {
      align-self: baseline;
    }

    input[type=number] {
      width: auto;
      justify-self: start;
    }
  }

</style>
