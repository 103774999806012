var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"frs-simple-table table table-striped table-condensed table-bordered table-hover table-responsive",class:{'fixed-header': _vm.fixedHeader}},[_c('thead',[_c('tr',{style:({width: _vm.headerWidth + 'px'})},_vm._l((_vm.headerData),function(column,i){return _c('th',{key:i,style:({width: column.width})},[_vm._t(("header-" + (column.id)),[((_vm.localizeHeaders || column.localize) && column.title)?_c('IxRes',[_vm._v(_vm._s(column.title))]):[_vm._v("\n            "+_vm._s(column.title)+"\n          ")]],{"title":column.title}),(column.sortable)?_c('SimpleTableSortingButton',{attrs:{"active":i === _vm.sortedBy,"descending":_vm.descending,"type":column.type},on:{"click":function($event){return _vm.sortBy(i)}}}):_vm._e()],2)}),0)]),_c('tbody',{ref:"tableBody",on:{"scroll":_vm.onScroll}},[(_vm.data.length)?[_vm._l((_vm.sortedData),function(row,i){return _vm._t("default",[_c('tr',{key:i,class:_vm.highlight ? _vm.highlight(row, i) : []},_vm._l((_vm.headerData),function(column,j){return _c('td',{key:j,style:({width: column.width}),on:{"click":function($event){_vm.$emit('click', {
              row: row,
              column: column,
              rowIndex: i,
              columnIndex: j,
              value: column.value(row, i),
              event: $event
            })}}},[_vm._t(("" + (column.id)),[_c('SimpleTableAutoCell',{attrs:{"value":column.value(row, i),"type":column.type,"format-string":column.formatString}})],{"row":row,"column":column,"rowIndex":i,"columnIndex":j,"value":column.value(row, i)})],2)}),0)],{"row":row,"index":i})})]:_c('tr',[_c('td',{staticClass:"no-data",attrs:{"colspan":_vm.headerData.length}},[_c('IxRes',[_vm._v("Common.SR_Common.SimpleTable.NoData")])],1)])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }