<template lang="html">
  <LabelAndMessage
    :required="required" :label="label"
    :feedback-classes="feedbackClasses"
    :description="combinedDescription" :message="message"
  >
    <div class="vue-selectize">
      <select ref="select" :name="name" />
    </div>
    <slot slot="label" name="label" />
    <slot slot="description" name="description" />
  </LabelAndMessage>
</template>

<script>
import 'selectize'
import {debounce, sortBy} from 'lodash'

import {getCurrentLanguage} from 'src/js/i18n'
import LabelAndMessage from 'src/vue/components/forms/input-base/LabelAndMessage'

import {mapResources} from '@helpers/vuex'
import {map} from '@helpers/objects'

import InputMixin from '@components/forms/input-base/InputMixin'
import FormPartMixin from '@components/forms/FormPartMixin'
import ChangeDetectionMixin from '@components/forms/ChangeDetectionMixin'
import RuleMixin from '@components/forms/RuleMixin'

const comboboxLabeledOption = (data, escape) => {
  if (data.label) {
    return `<div><span>${escape(data.text)}</span> <span class='combobox-option-label'>${escape(data.label)}</span></div>`
  } else {
    return `<div><span>${escape(data.text)}</span></div>`
  }
}

const comboboxLabeledItem = (data, escape) => {
  if (data.label) {
    return `<div><span>${escape(data.text)}</span> <span class='combobox-item-label'>${escape(data.label)}</span></div>`
  } else {
    return `<div><span>${escape(data.text)}</span></div>`
  }
}

const Empty = Symbol('VueSelectize internal empty value')
const optionTemplate = {
  text: null,
  value: null,
  label: null,
  group: null
}

export default {
  components: {
    LabelAndMessage
  },
  mixins: [
    InputMixin,
    FormPartMixin,
    ChangeDetectionMixin,
    RuleMixin
  ],
  props: {
    options: {
      type: Array,
      required: true
    },
    groups: Array,
    value: {},
    multiple: Boolean,
    fields: Object,
    maxItems: Number,
    create: [Boolean, Function],
    placeholder: String,
    label: String,
    allowEmpty: Boolean,
    ordered: Boolean,
    sortField: {},
    useBodyAsParent: {
      default: false,
      type: Boolean
    },
    disabled: {
      type: Boolean,
      default: false
    },
    description: String,
    required: Boolean,
    name: String,
    resetValue: {},
    comboboxLabeledOption: {
      type: Function,
      default: () => comboboxLabeledOption
    },
    comboboxLabeledItem: {
      type: Function
    }
  },
  computed: {
    ...mapResources([
      'Messages.Info.SR_InfoMessages'
    ]),
    // make sure that a field config object exists with all keys for other functions
    sanitizedFieldConfig () {
      return map(optionTemplate, (x, key) => (this.fields || {})[key] || key)
    },
    // make sure there is always a valid maxItems value for other functions
    sanitizedMaxItems () {
      return this.maxItems || (this.multiple ? null : 1)
    },
    selectize () {
      return this.$refs.select.selectize
    },
    internalOptions () {
      if (!this.allowEmpty && !this.options.length) {
        throw new Error('[VueSelectize] empty options array but allowEmpty prop is false')
      }

      const sortedOptions = this.ordered
        ? this.options
        : sortBy(this.options, [this.sortField || (x => this.resolveField(x, 'text'))])

      const options = sortedOptions.map(this.convertOption)

      if (this.allowEmpty && this.sanitizedMaxItems === 1 && !this.required) {
        options.unshift(this.emptyOption)
      }

      return options
    },
    emptyOption () {
      return {
        $order: 0,
        value: Empty.toString(),
        text: this.placeholder ? this.placeholder.toString() : this.SR_InfoMessages.NoSelection
      }
    },
    internalGroups () {
      if (this.groups) return this.groups.map(x => typeof x === 'object' ? x : {value: x, label: x})

      const groups = {}
      this.internalOptions.forEach(option => {
        if (option.group) {
          if (typeof option.group === 'object') {
            groups[option.group.value] = option.group
          } else {
            groups[option.group] = {value: option.group, label: option.group}
          }
        }
      })

      return Object.keys(groups).map(x => groups[x])
    },
    internalItems () {
      const normalize = value => typeof value === 'object'
        ? this.resolveField(value, 'value')
        : value

      let items

      if (this.sanitizedMaxItems === 1) {
        if (this.value !== null) {
          items = [normalize(this.value)]
        } else if (this.allowEmpty && this.value === null) {
          items = []
        } else {
          throw new Error(`[VueSelectize] invalid value for single select: '${this.value}'`)
        }
      } else {
        if (!Array.isArray(this.value)) {
          throw new Error(`[VueSelectize] invalid value for multiple select: '${this.value}'`)
        }

        if (!this.allowEmpty && !this.value.length) {
          throw new Error('[VueSelectize] allowEmpty is false, empty array value not allowed')
        }

        items = this.value.map(normalize)
      }

      const correspondingOptions = items.map(x => this.internalOptions.find(option => option.value === x))

      if (correspondingOptions.some(x => !x)) {
        const invalidIndices = correspondingOptions.map((x, i) => [!x, i]).filter(x => x[0]).map(x => x[1])

        throw new Error(`[VueSelectize] no corresponding option found for values: indices '${invalidIndices}' in values '${this.value}'`)
      }

      return items
    },
    combinedDescription () {
      return [this.ruleDescription, this.description].filter(x => x)
    },
    message () {
      const builtinMessages = {
        error: this.SR_InfoMessages.RequiredField
      }
      const combinedMessages = Object.assign(builtinMessages, this.ruleMessages)

      return combinedMessages[this.state]
    },
    state () {
      // never validate without user entry
      if (!this.dirty) {
        return undefined
      }

      const states = {}

      if (this.required) {
        if (this.value !== null) {
          states.required = 'success'
        } else {
          states.required = 'error'
        }
      }
      states.rule = this.ruleState

      // the 'or success' part coerces the undefined state that you get when no rules are available into a success state
      return this.combineStates(states) || 'success'
    }
  },
  methods: {
    resolveField (option, field) {
      const resolve = field => {
        const mapping = this.sanitizedFieldConfig[field]

        return mapping instanceof Function
          ? mapping(option)
          : typeof option === 'object'
            ? option[mapping]
            // also support primitive options like strings and numbers
            : field === 'value' || field === 'text'
              ? option
              : undefined
      }

      const fieldValue = resolve(field)

      if (fieldValue === undefined && field === 'value') {
        return resolve('text')
      }
      if (fieldValue === undefined && field === 'text') {
        return resolve('value')
      }

      return fieldValue
    },
    // make selectize option object from actual option
    convertOption (option) {
      return map(this.sanitizedFieldConfig, (x, field) => this.resolveField(option, field))
    },
    // get a reference to one of the external options by its value
    getOption (value) {
      return this.options.find(option => typeof option === 'object'
        ? this.resolveField(option, 'value') === value
        : option === value)
    },
    // communicate changed value to parent
    onSelectionChanged () {
      if (this.$_VueSelectize_internalUpdate) {
        return
      }

      const selectedItems = this.selectize.items
        .filter(x => x !== Empty)
        .map(x => this.selectize.options[x].value) // NOTE probably due to type conversion of selectize items to string?
        .map(this.getOption)
        .filter(x => x !== undefined)

      const finalSelection = this.sanitizedMaxItems === 1
        ? selectedItems[0] === undefined
          ? this.allowEmpty
            ? null
            : this.value
          : selectedItems[0]
        : !selectedItems.length && !this.allowEmpty
          ? this.value
          : selectedItems

      if (this.value !== finalSelection) {
        this.$emit('input', finalSelection)
      } else {
        // rollback selection change, since the new selection is invalid
        // update internal selectize, emitting the same value again would not trigger `value` watcher
        this.updateValues()
      }
    },
    // handles the change event on the DOM select, necessary since selectize triggers
    // a change event here upon removing the option using .clearOptions()
    onSelectChanged (evt) {
      if (this.$_VueSelectize_internalUpdate) {
        evt.preventDefault()
        evt.stopPropagation()
      }
    },
    onType (str) {
      this.$emit('type', str)
    },
    // update selectize options from props
    updateOptions () {
      this.$_VueSelectize_internalUpdate = true

      this.selectize.clearOptions()
      this.selectize.addOption(this.internalOptions)
      this.selectize.refreshOptions(false)

      this.selectize.clearOptionGroups()
      this.internalGroups.forEach(group => {
        this.selectize.addOptionGroup(group.value, group)
      })

      this.$_VueSelectize_internalUpdate = false

      this.updateValues()
    },
    // update selectize items from props
    updateValues () {
      this.$_VueSelectize_internalUpdate = true

      this.selectize.clear(true)

      this.internalItems.forEach(item => {
        this.selectize.addItem(item, true)
      })

      this.$_VueSelectize_internalUpdate = false
    },
    focus () {
      this.selectize.focus()
    },
    setDisable () {
      if (this.disabled) {
        this.selectize.disable()
      } else {
        this.selectize.enable()
      }
    }
  },
  // selectize needs to be updated non-reactively
  watch: {
    value: 'updateValues',
    options: 'updateOptions',
    allowEmpty: 'updateOptions',
    disabled () {
      this.setDisable()
    }
  },
  // all setup for selectize has to happen when the DOM elements are all ready
  mounted () {
    const options = {
      optgroupField: 'group',
      create: this.create,
      maxItems: this.sanitizedMaxItems,
      selectOnTab: true,
      placeholder: this.placeholder
        ? this.placeholder.toString()
        : this.allowEmpty
          ? this.SR_InfoMessages.NoSelection
          : this.SR_InfoMessages.PleaseSelectSomething,
      allowEmptyOption: this.allowEmpty,
      dropdownParent: this.useBodyAsParent ? 'body' : null
    }

    options.render = {
      option: comboboxLabeledOption,
      item: this.comboboxLabeledItem || comboboxLabeledItem
    }

    options.searchField = ['text', 'label']

    if (this.sanitizedMaxItems !== 1) {
      options.plugins = {'remove_button': {label: '<i class="fa fa-times"></i>'}}
    }

    const $select = $(this.$refs.select)
    $select.selectize(options)

    this.setDisable()

    if (this.sanitizedMaxItems > 1) {
      const $maxSelectionInfo = $('<div class="selectize-max-selected-message fade">')
        .prependTo($select.next())

      // TODO use resource
      switch (getCurrentLanguage()) {
      case 'de':
        $maxSelectionInfo.text('Auswahllimit erreicht')
        break
      default:
        $maxSelectionInfo.text('Selection limit reached')
      }

      $select.on('change', () => {
        if (this.selectize.items.length === options.maxItems) {
          $maxSelectionInfo.addClass('in')
        } else {
          $maxSelectionInfo.removeClass('in')
        }
      })
    }

    // fire event when user changes the selection
    this.selectize.on('change', this.onSelectionChanged)

    // fire event when user types something into the selectize box
    this.selectize.on('type', debounce(this.onType, 200))

    // hook DOM select change event, this is used to prevent change event propagation on internal updates
    $select.on('change', this.onSelectChanged)

    // set inital focus when component is loaded
    // this.selectize.focus()

    this.updateOptions(this.options)
  },
  beforeDestroy () {
    $(this.$refs.select).off('change')

    this.selectize.destroy()
  }
}
</script>

<style lang="scss" scoped>
</style>
