<template>
  <ListCard
    :title="title"
    :subtitle="poultryHousesTitle"
  />
</template>

<script>
import {mapResources} from '@helpers/vuex'

import ListCard from '@components/card/ListCard'
import SelfDestruct from '@mixins/SelfDestruct'

export default {
  components: {
    ListCard
  },
  mixins: [
    SelfDestruct
  ],
  props: {
    farm: null
  },
  computed: {
    ...mapResources(['Areas.PoultryManagement.SR_PoultryManagement']),
    title () {
      return this.farm && this.farm.name ? this.farm.name : ''
    },
    poultryHousesTitle () {
      return this.farm && this.farm.countHouses ? this.farm.countHouses + ' ' + this.SR_PoultryManagement.CorrespondingPoultryHouses : ''
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>

 :hover {
    background-color: aliceblue;
  }

  .selected {
    background-color: lightgray;
  }
</style>
