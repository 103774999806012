<template>
  <div class="poultry-house-overview-container">
    <h3>
      <span>{{ totalPoultryHouses }} {{ ' ' }} </span>
      <IxRes>poultryHouses</IxRes>
    </h3>
    <SimpleTable :columns="columns" :data="internalPoultryHouses" localize-headers>
      <template slot="manage" slot-scope="{row}">
        <a @click="goToPoultryHouse(row.poultryHouseNumber)">
          <IxRes>manage</IxRes>
        </a>
      </template>
    </SimpleTable>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import {cloneDeep} from 'lodash'

import {mapResources} from '@helpers/vuex'

import SimpleTable from '@components/table/SimpleTable'

export default {
  translationNamespace: 'Areas.PoultryManagement.SR_PoultryManagement',

  components: {
    SimpleTable
  },
  computed: {
    ...mapGetters('poultryManagement/productionManager', [
      'poultryHouses',
      'totalPoultryHouses'
    ]),
    ...mapResources([
      'Areas.PoultryManagement.SR_PoultryManagement'
    ]),
    columns () {
      return {
        poultryHouseNumber: {title: 'Areas.PoultryManagement.SR_PoultryManagement.Number', sortable: true},
        flocks: {title: 'Areas.PoultryManagement.SR_PoultryManagement.InstabledFlocks', sortable: false},
        manage: {title: '', sortable: false}
      }
    },
    internalPoultryHouses () {
      let houses = cloneDeep(this.poultryHouses)
      houses.forEach(house => {
        if (house.hasMultiple && !house.flocks.includes(this.SR_PoultryManagement.flocks)) {
          house.flocks = house.flocks.concat(' ' + this.SR_PoultryManagement.flocks)
        }
      })
      return houses
    }
  },
  methods: {
    ...mapActions('poultryManagement/productionManager', [
      'goToPoultryHouse'
    ])
  }
}

</script>

<style lang="scss">
  a {
    cursor: pointer;
  }
</style>
