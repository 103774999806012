<template>
  <div class="simple-switch" :class="{checked: value}">
    <div v-if="hasOffSlotOrLabel" class="switch-label off-label" @click="modelProxy = false">
      <slot name="offLabel">
        {{ offLabel }}
      </slot>
    </div>
    <div class="switch" :class="{'bg-primary': value}" @click="modelProxy = !modelProxy">
      <input v-model="modelProxy" type="checkbox">
      <span :class="{slider: true, translate: value}" />
    </div>
    <div class="switch-label on-label" @click="modelProxy = (!hasOffSlotOrLabel ? !modelProxy : true)">
      <slot />
    </div>
  </div>
</template>

<script>
import {modelProxy} from '@helpers/vuex'

export default {
  props: {
    value: Boolean,
    offLabel: String
  },
  computed: {
    hasOffSlotOrLabel () {
      return this.$slots.offLabel || this.offLabel
    },
    modelProxy
  }
}
</script>

<style lang="scss" scoped>
.simple-switch {
  display: flex;
  margin-bottom: 5px;

  .switch-label {
    cursor: pointer;
    transition: color .2s;
  }
}

.simple-switch:not(.checked) {
  .switch {
    background-color: #ccc;
  }

  .switch-label.off-label {
    color: #ffa500;
  }
}

.simple-switch.checked {
  .switch-label.on-label {
    color: #ffa500;
  }
}

.switch-label.off-label {
  margin-right: 6px;
}

.switch-label.on-label {
  margin-left: 6px;
}

.switch {
  display: flex;
  align-items: center;
  height: 20px;
  width: 36px;
  min-width: 36px;
  border-radius: 100px;
  transition: all .2s;
  cursor: pointer;
}

.slider {
  height: 16px;
  width: 16px;
  margin-left: 2px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);

  transition: all .2s;
}

.translate {
  transform: translateX(16px);
}

input {
  display: none;
}
</style>
